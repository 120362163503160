export const getRule = (rule: string, args?: any) => {
  switch (rule) {
    case "required":
      return { required: true };
      break;

    default:
      return {};
      break;
  }
};

export const getMessageByType = (rule: string) => {
  switch (rule) {
    case "required":
      return "Requerido";
      break;

    default:
      return "";
      break;
  }
};
