import { getMessageByType } from "../../utils/formRules";

export const PasswordFormInput = ({ formControl, errors, label }: any) => (
  <div className="relative">
    <label className="text-brand-blue font-inter text-sm font-medium">
      {label}
    </label>
    <input
      {...formControl}
      id={formControl.name}
      className="mt-2 pl-4 bg-brand-gray-100 w-full text-base font-inter font-medium h-12 rounded-lg"
      type="password"
    />
    {errors && errors[formControl.name] ? (
      <span className="absolute right-2 mt-4 bg-brand-lightred py-1.5 px-3 rounded font-inter text-sm font-medium text-brand-red ">
        {getMessageByType(errors[formControl.name].type)}
      </span>
    ) : (
      ""
    )}
  </div>
);
