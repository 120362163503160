import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../store";

// Define a type for the slice state

export interface INewspapper {
  status: string;
  name: string;
  uuid: string;
}

interface NewspapperState {
  newspapperList: INewspapper[];
  newspapper: INewspapper;
  pending: boolean;
  hasFetched: boolean;
}

// Define the initial state using that type
const initialState: NewspapperState = {
  newspapperList: [],
  pending: false,
  hasFetched: false,
  newspapper: null!,
};

export const newspapperSlice = createSlice({
  name: "newspapper",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      state.pending = true;
    },

    stopFetch: (state, action: PayloadAction<INewspapper[] | any>) => {
      state.pending = false;
      state.hasFetched = true;
      if (action && action.payload) state.newspapperList = action.payload;
    },

    selectedFetch: (state, action: PayloadAction<INewspapper>) => {
      state.newspapper = action.payload;
    },
  },
});

export const { startFetch, stopFetch, selectedFetch } = newspapperSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const newspapperList = (state: RootState) =>
  state.newspapperSlice.newspapperList;

export const newspapperPending = (state: RootState) =>
  state.newspapperSlice.pending;

export const newspapperHasFetched = (state: RootState) =>
  state.newspapperSlice.hasFetched;

export default newspapperSlice.reducer;
