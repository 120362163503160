import axios from "../utils/axios";
import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { asError, processActionService } from "../utils/axios";
import { showToast } from "../utils/common";
import { isValidToken, setSession, setUserSession } from "../utils/jwt";
import { cleanStorage, fromStorage, toStorage } from "../utils/storage";

const initialState = {
  isAuthenticated: false,
  pending: false,
  hasFetched: false,
  sessionData: { role: '' },
  accessToken: "",
};

const handlers: any = {
  INITIALIZE: (state: any, action: any) => {
    const { isAuthenticated, sessionData } = action.payload;
    return {
      ...state,
      isAuthenticated,
      sessionData,
    };
  },
  LOGIN: (state: any, action: any) => {
    return {
      ...state,
      pending: false,
      isAuthenticated: true,
      sessionData: { ...state.sessionData, ...action.payload },
    };
  },
  LOGOUT: (state: any) => ({
    ...state,
    isAuthenticated: false,
    sessionData: null,
    pending: false,
    hasFetched: false,
  }),
  UPDATE_ACCOUNT: (state: any, action: any) => {
    const { sessionData } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      sessionData: { ...state.sessionData, ...sessionData },
      pending: false,
      hasFetched: true,
    };
  },

  START: (state: any) => {
    return {
      ...state,
      pending: true,
    };
  },

  STOP: (state: any) => {
    return {
      ...state,
      pending: false,
    };
  },
};

const reducer = (state: any, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: (email: string, password: string, callback: Function) =>
    Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  userInfo: () => Promise.resolve(),
});


// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = fromStorage("DOBLED-TOKEN");
        const sessionData = fromStorage("DOBLED-ADMIN-USERSESION")
          ? JSON.parse(fromStorage("DOBLED-ADMIN-USERSESION") as any)
          : null;

        if (accessToken) {
          if (isValidToken(accessToken.replace("Bearer ", ""))) {
            setSession(accessToken);
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                sessionData: { ...sessionData, token: accessToken },
              },
            });
          }
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              sessionData: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            sessionData: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (email: string, password: string, callback: Function) => {
    dispatch({
      type: "START",
    });
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    processActionService("/users/login", "post", formData)
      .then((response) => {
        const { code, token } = response;
        if (!!token) {
          toStorage("DOBLED-TOKEN", token);
          axios.defaults.headers.common.Authorization = `${token}`;
          dispatch({
            type: "LOGIN",
            payload: {
              token: token,
            },
          });
          if (typeof callback === "function") callback();
        }

        else {
          if (asError(response.code)) showToast(response.error);
        }
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };
  const userInfo = async (callback: Function) => {
    dispatch({
      type: "START",
    });
    processActionService("/users", "get", {})
      .then((response) => {
        console.log(response);

        const { data } = response;
        if (data) {
          dispatch({
            type: "UPDATE_ACCOUNT",
            payload: {
              sessionData: data,
              isAuthenticated: true,
            },
          });
        } else {
          if (asError(response.code)) showToast(response.error);

        }

      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };
  const logout = async () => {
    setSession(null);
    setUserSession(null);
    cleanStorage("SELECTED-ORG");
    cleanStorage("SELECTED-EMISSION");
    dispatch({ type: "LOGOUT" });
    delete axios.defaults.headers.common.Authorization;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
