import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import { getMessageByType } from "../../utils/formRules";

export const SelectFormInput = ({
  formControl,
  errors,
  label,
  name,
  defaultValue,
  placeholder,
  option = [],
  keyValue = "value",
  keyName = "label",
  disabled = false,
  onChange,
  style={}
}: any) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="mt-3 relative " style={style}>
        <div className="text-brand-blue font-inter text-sm font-medium  " style={{minHeight:20,}}>
          {label}
        </div>

        <Controller
          name={name}
          control={formControl}
          rules={{ required: true }}
          render={({ field }) => (
            <div onBlur={() => setOpen(false)} className="relative">
              <button
                type="button"
                onClick={() => setOpen(!open)}
                style={{ height: 50 }}
                className="flex justify-between h-130 w-full px-2 py-3 mt-2 text-sm font-medium font-inter  bg-white rounded-lg border border-brand-blue focus:outline-none"
              >
                {field.value ? (
                  <span className="text-brand-blue">
                    {field.value[keyName]}
                  </span>
                ) : (
                  <span className="text-brand-gray-200">{placeholder}</span>
                )}

                {errors && errors[name] ? (
                  <span
                    style={{ marginTop: -4 }}
                    className="absolute right-2 mr-8 bg-brand-lightred py-1.5 px-3 rounded font-inter text-sm font-medium text-brand-red "
                  >
                    {getMessageByType(errors[name].type)}
                  </span>
                ) : (
                  ""
                )}
                <svg
                  className={
                    (open ? "rotate-180" : "rotate-0") +
                    "inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform text-brand-blue"
                  }
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>

              <CSSTransition
                in={open}
                timeout={350}
                classNames="display"
                unmountOnExit
              
              >
                <div style={{height:80}} className="absolute left-0 w-full mt-2 origin-top-right rounded-md md:w-full bg-white border z-10">
                  <div className="px-2 py-2 bg-white rounded-md max-h-40 overflow-y-auto">
                    {option.map((e: any, i: number) => (
                      <a
                        key={i}
                        className="block px-4 py-2 text-sm font-medium font-inter text-brand-blue"
                        href="#"
                        onClick={() => {
                          if (typeof onChange === 'function')
                            onChange(e)
                          field.onChange(e)
                        }}
                      >
                        {e[keyName]}
                      </a>
                    ))}
                  </div>
                </div>
              </CSSTransition>
            </div>
          )}
        />
      </div>
    </>
  );
};
