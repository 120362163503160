import Dropzone from "react-dropzone";
import { Controller } from "react-hook-form";
import { getMessageByType } from "../../utils/formRules";

export const UploadFormInput = ({
  formControl,
  errors,
  label,
  name,
  defaultValue,
  placeholder,
  setFileInfo,
  disabled = false,
}: any) => {
   

  return (
    <>
      <div className="mt-3 relative">
        <label className="text-brand-blue font-inter text-sm font-medium">
          {label}
        </label>

        <Controller
          name={name}
          control={formControl}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <Dropzone
                multiple={true}
                disabled={disabled}
                onDrop={(e) => {
                  console.log(e);
                  
                  setFileInfo(e);
                  field.onChange(e);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="w-full mt-5 bg-brand-gray-100 border py-20 px-10 rounded-lg">
                        <button className="block text-brand-blue text-base font-inter font-bold mx-auto">
                          Drop a file here or click to upload
                        </button>
                        
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
              {errors && errors[name] ? (
                <span
                  style={{ marginTop: -4 }}
                  className="absolute right-2 mr-8 bg-brand-lightred py-1.5 px-3 rounded font-inter text-sm font-medium text-brand-red "
                >
                  {getMessageByType(errors[name].type)}
                </span>
              ) : (
                ""
              )}
            </>
          )}
        />
      </div>
    </>
  );
};
