import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CallersSetting } from "../../components/callers/CallersSetting";
import { FormCallers } from "../../components/callers/FormCallers";
import { MdConfirm } from "../../components/common/MdConfirm";
import EmissionCombo from "../../components/emission/EmissionCombo";
import ModulesEmission from "../../components/emission/ModulesEmission";
import NewsPapperEmission from "../../components/emission/NewsPapperEmission";
import SlideEmissionList from "../../components/emission/SlideEmissionList";
import SportEmission from "../../components/emission/SportEmission";
import UploadModalEmission from "../../components/emission/UploadModalEmission";
import {
  emissionOne,
  selectedFetch,
  startFetch,
} from "../../redux/feature/emissionSlice";
import { modalCallersForm, modalConfirm, modalReel, modalUploadForm, switchReel } from "../../redux/feature/modalSlice";
import { inicializeModule, moduleInfo, modulePending, updateModule } from "../../redux/feature/moduleSlice";
import { stopFetch } from "../../redux/feature/organizationSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { processActionService } from "../../utils/axios";
import { toStorage } from "../../utils/storage";
import { BackgroundBlur } from "../common/BackgroundBlur";
import { Header } from "../common/Header";
import { stopFetch as stopFetchModule } from "../../redux/feature/moduleSlice";
import { startFetch as startFetchModule } from "../../redux/feature/moduleSlice";
import LiveNowEmission from "../../components/emission/LiveNowEmission";
import useAuth from "../../hooks/useAuth";
import DeviceEmission from "../../components/emission/DeviceEmission";
import { deleteDeviceFromEmission, showToast, updateEmissionData } from "../../utils/common";
import { PreviewReel } from "../../components/common/PreviewReel";
import ThemeEmission from "../../components/emission/ThemeEmission";
import { SportSetting } from "../../components/sport/SportSetting";
import ThemeEmissionFirebase from "../../components/emission/ThemeEmissionFirebase";

const DialScreen = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const emission = useAppSelector(emissionOne);
  const modalUpload = useAppSelector(modalUploadForm);
  const confirm = useAppSelector(modalConfirm);
  const modalCallers = useAppSelector(modalCallersForm);
  const modalReelAction = useAppSelector(modalReel);

  const [call, setCall] = useState("");

  useEffect(() => {
    toStorage("SELECTED-EMISSION", params.emision_uuid)
  }, [params.emision_uuid]);

  const createArray = (n: number): any => {
    return Array(n).fill(0).map((_, index) => index + 1);
  }

  const callDial = (): any => {
    if (call && call !== "") {
      updateEmissionData(params.emision_uuid ?? '', {
        numberCaller: parseInt(call)
      });
    }
  }



  return (
    <>

      <Header back={true} />
      {(modalUpload || modalReelAction || modalCallers?.show || confirm) && <BackgroundBlur />}
      {modalUpload && <UploadModalEmission />}
      {confirm && <MdConfirm />}
      {modalCallers?.show && <FormCallers />}
      {modalReelAction && <PreviewReel />}
      {!modalUpload && !modalReelAction && !modalCallers?.show && !confirm && (
        <section>
          <div className="bg-brand-gray w-full h-full pb-8">
            <div className="w-full px-4 lg:px-8 2xl:max-w-screen-2xl mx-auto pt-4 flex justify-center ">
              <div className="  h-[500px] w-[300px]">
                <div className="flex flex-row justify-center items-center center text-center gap-4">
                  <div className="flex flex-row justify-center items-center center h-10 w-[70%] bg-gray-200 ml-4 mt-4 text-2xl font-bold"> {call} </div>
                  <div onClick={() => setCall(`${call.slice(0, -1)}`)} className="flex flex-row text-2xl mt-2 cursor-pointer">x</div>
                </div>

                <div className="mt-10 flex flex-row flex-wrap justify-center items-center center text-center gap-4">
                  {createArray(10).map((n: number, i: number) =>
                    <div onClick={() => setCall(`${call + "" + n}`)} className="rounded-full bg-white hover:bg-brand-green hover:text-white  cursor-pointer p-1 w-16 h-16 text-2xl font-bold flex justify-center items-center" key={i}>{n}</div>
                  )}
                </div>

                <div className="mt-10 flex flex-row justify-center items-center center text-center gap-4">
                  <div onClick={() => callDial()} className="flex cursor-pointer flex-row justify-center items-center center h-10 w-[70%] bg-brand-green ml-4 mt-4 text-white font-bold "> Call </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DialScreen;
