import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../store";

// Define a type for the slice state

export interface ITriviaOption {
  text: string;
  is_correct: boolean;
}
export interface ITrivia {
  question?: string;
  type?: string;
  options?: ITriviaOption[];
  category?: string;
}

interface TriviaState {
  triviaList: ITrivia[];
  trivia: ITrivia;
  pending: boolean;
  hasFetched: boolean;
}

// Define the initial state using that type
const initialState: TriviaState = {
  triviaList: [],
  pending: false,
  hasFetched: false,
  trivia: null!,
};

export const TriviaSlice = createSlice({
  name: "trivia",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      state.pending = true;
    },

    stopFetch: (state, action: PayloadAction<ITrivia[] | any>) => {
      if (action.payload) state.triviaList = action.payload;
      state.pending = false;
      state.hasFetched = true;
    },

    selectedFetch: (state, action: PayloadAction<ITrivia>) => {
      state.trivia = action.payload;
    },
  },
});

export const { startFetch, stopFetch, selectedFetch } = TriviaSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const triviaList = (state: RootState) => state.triviaSlice.triviaList;
export const triviaPending = (state: RootState) => state.triviaSlice.pending;
export const triviaHasFetched = (state: RootState) =>
  state.triviaSlice.hasFetched;

export default TriviaSlice.reducer;
