import React, { useEffect } from "react";
import "./App.css";
import Router from "./routes";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { PATH_AUTH } from "./routes/paths";

localStorage.setItem("isTrueE","0")
function App() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const location = useLocation();
  useEffect(() => {
    if (!isAuthenticated) navigate(PATH_AUTH.login);
  }, [isAuthenticated]);

  useEffect(() => {
     if(location.pathname.includes("emission")){
      localStorage.setItem("isTrueE","1")
     }
     
  }, [location]);

  return (
    <>
      <ToastContainer />
      <Router />
    </>
  );
}

export default App;
