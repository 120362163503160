import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import avatar from "../../assets/images/avatar.png";
import useAuth from "../../hooks/useAuth";
import { resetHasFetched } from "../../redux/feature/organizationSlice";
import { useAppDispatch } from "../../redux/hooks";

export const Header = ({back=false}:any) => {
  const [open, setOpen] = useState(false);
  const { logout, pending, userInfo, sessionData, hasFetched }: any = useAuth();
  const navigation = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const isRootPath = () => location.pathname === "/";



  const [hasCall, setHasCall] = useState(false);

  const fetchUserInfo = useCallback(() => {
    if (!hasCall) {
      userInfo();
      setHasCall(true);
    }
  }, [hasCall, userInfo]);

  useEffect(() => {
    if (!hasFetched) fetchUserInfo();
  }, [fetchUserInfo, hasFetched]);

  const createOrganization = () => navigation("/crear-organization");
  const goDashboard = () => {
    if (!isRootPath()) navigation(`/organization/${params.organization_uuid}`);
  };

  const goCaller = () => {
    if (!isRootPath()) navigation(`/organization/${params.organization_uuid}/emission/${params.emision_uuid}/dial`);
  };

  const goEmission = () => {
    if (!isRootPath()) navigation(`/organization/${params.organization_uuid}/emission/${params.emision_uuid}`);
  };

  return (
    <header>
      <div className="bg-white">
        <div className="w-full px-4 lg:px-8 2xl:max-w-screen-2xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12">
            <div className="col-span-1 lg:col-span-8 xl:col-span-9 flex justify-between w-full 2xl:max-w-screen-2xl mx-auto px-4 lg:px-8 py-4">
              <div className="flex sm:justify-between  xs:flex-row sm:flex-row gap-10 w-full mr-10">
                {/* <!-- logo -->*/}
                <div
                  onClick={goDashboard}
                  className="flex gap-3 mt-3 cursor-pointer"
                >
                  <svg
                    width="34"
                    height="41"
                    viewBox="0 0 34 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.6896 -0.000244141L16.4245 7.56541L19.0332 10.078L20.3404 5.53184L31.0899 16.7053L27.8061 30.5798L26.1162 29.2502L24.8417 28.2493L22.6167 26.4912L21.2115 25.3857L15.8503 21.1645L17.1042 16.7996L14.3555 14.4485L12.0054 22.284L20.3983 28.4781L21.8287 29.5369L24.1069 31.2203L25.4066 32.1783L29.0909 34.9019L33.4129 16.0274L18.6896 -0.000244141Z"
                      fill="#0059B1"
                    />
                    <path
                      d="M20.0789 36.3202L4.97654 26.7714L10.7243 8.81753L13.2144 11.0238L14.9306 12.5392L17.642 14.9322L19.1826 16.2926L24.1218 20.6679L23.0826 24.6967L25.2805 26.4931L26.9564 19.7603L20.021 13.2899L18.514 11.8791L15.8699 9.41042L14.1929 7.84743L8.62352 2.64307L0 28.3624L21.6671 40.9998L23.654 33.0299L21.3525 31.3922L20.0789 36.3202Z"
                      fill="#A0C51E"
                    />
                  </svg>
                  <svg
                    className="mt-2"
                    width="101"
                    height="22"
                    viewBox="0 0 101 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.48549 1.92432C14.3948 1.92432 18.5067 5.81871 18.5067 11.4563C18.5067 17.0659 14.3406 20.9874 8.32116 20.9874H0.396973V1.92432H8.48549ZM4.6994 17.3656H8.59379C11.7805 17.3656 14.1221 14.9688 14.1221 11.4834C14.1221 7.96993 11.6712 5.54701 8.40333 5.54701H4.6994V17.3656Z"
                      fill="#0059B1"
                    />
                    <path
                      d="M35.7145 13.6615C35.7145 18.1273 32.5558 21.1506 27.8715 21.1506C23.1611 21.1506 20.0015 18.1273 20.0015 13.6615C20.0015 9.16858 23.1601 6.19946 27.8715 6.19946C32.5558 6.19946 35.7145 9.16765 35.7145 13.6615ZM24.2227 13.7156C24.2227 16.1124 25.6932 17.7463 27.8715 17.7463C30.0227 17.7463 31.5213 16.1124 31.5213 13.7156C31.5213 11.3189 30.0236 9.68491 27.8715 9.68491C25.6932 9.68585 24.2227 11.3189 24.2227 13.7156Z"
                      fill="#0059B1"
                    />
                    <path
                      d="M54.3409 13.7436C54.3409 18.1823 51.6444 21.1505 47.5324 21.1505C45.4895 21.1505 43.8836 20.3335 42.8211 18.863V20.9871H38.627V0.780273H42.8211V8.48691C43.8556 7.01636 45.4625 6.19938 47.4503 6.19938C51.5622 6.19938 54.3409 9.22265 54.3409 13.7436ZM50.0917 13.6063C50.0917 11.2646 48.6211 9.57559 46.4699 9.57559C44.3187 9.57559 42.8211 11.2366 42.8211 13.6063C42.8211 16.0302 44.3187 17.6641 46.4699 17.6641C48.6211 17.6641 50.0917 16.0031 50.0917 13.6063Z"
                      fill="#0059B1"
                    />
                    <path
                      d="M57.2251 20.9871V0.780273H61.4192V20.9871H57.2251Z"
                      fill="#0059B1"
                    />
                    <path
                      d="M79.091 14.9416H68.5787C69.0688 16.7931 70.4852 17.8827 72.3648 17.8827C73.7531 17.8827 75.0603 17.3384 76.0677 16.331L78.274 18.5643C76.8035 20.1983 74.6523 21.1516 72.0109 21.1516C67.3266 21.1516 64.3584 18.1563 64.3584 13.7176C64.3584 9.19667 67.4358 6.20142 71.9016 6.20142C77.0491 6.19955 79.4449 9.60376 79.091 14.9416ZM75.1695 12.4636C75.1154 10.53 73.8624 9.27698 71.9287 9.27698C70.0772 9.27698 68.8242 10.53 68.4974 12.4636H75.1695Z"
                      fill="#0059B1"
                    />
                    <path
                      d="M90.0368 1.92432C95.9461 1.92432 100.058 5.81871 100.058 11.4563C100.058 17.0659 95.8919 20.9874 89.8724 20.9874H81.9482V1.92432H90.0368ZM86.2507 17.3656H90.1451C93.3317 17.3656 95.6734 14.9688 95.6734 11.4834C95.6734 7.96993 93.2225 5.54701 89.9546 5.54701H86.2507V17.3656Z"
                      fill="#A0C51E"
                    />
                  </svg>
                </div>
                {location.pathname.includes("/emission/") && !back && (
                  <button
                    onClick={goCaller}
                    className="mt-2 flex gap-1.5 hover:bg-white hover:text-brand-green hover:border hover:border-brand-green    text-white bg-brand-green px-4 py-2.5 h-12 text-lg font-inter font-medium  rounded-xl"
                  >

                    Caller

                    <svg
                      className="mt-2.5"
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 4.16667H3.19167L6.175 1.175L5 0L0 5L5 10L6.175 8.825L3.19167 5.83333H11.5V4.16667Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  </button>
                )}

                {location.pathname.includes("/emission/") && back && (
                  <button
                    onClick={goEmission}
                    className="mt-2 flex gap-1.5 hover:bg-white hover:text-brand-green hover:border hover:border-brand-green    text-white bg-brand-green px-4 py-2.5 h-12 text-lg font-inter font-medium  rounded-xl"
                  >
                   <svg
                      className="mt-2.5"
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 4.16667H3.19167L6.175 1.175L5 0L0 5L5 10L6.175 8.825L3.19167 5.83333H11.5V4.16667Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                    Go to emission

                  
                  </button>
                )}
              </div>
              {!back && <div className="flex justify-end gap-6">

                {/* 
                <button className="absolute top-10 right-28 sm:top-0 sm:right-0 sm:relative">
                  <svg
                    width="20"
                    height="24"
                    viewBox="0 0 20 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.84615 24C11.2062 24 12.3077 22.8985 12.3077 21.5385H7.38462C7.38462 22.8985 8.48615 24 9.84615 24ZM17.2308 16.6154V10.4615C17.2308 6.67692 15.2185 3.52 11.6923 2.68308V1.84615C11.6923 0.824615 10.8677 0 9.84615 0C8.82462 0 8 0.824615 8 1.84615V2.68308C4.47385 3.52 2.46154 6.67692 2.46154 10.4615V16.6154L0 19.0769V20.3077H19.6923V19.0769L17.2308 16.6154Z"
                      fill="#0059B1"
                    />
                  </svg>
                  <span className="-mt-8  shadow-lg absolute h-5 w-5 bg-brand-green rounded-full border-4 border-[#FEFEFE]"></span>
                </button>
                */}
                <div onBlur={() => setOpen(false)} className="relative ">
                  <button onClick={() => setOpen(!open)} className="relative">
                    {pending && (
                      <div className="loader ease-linear  rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mt-4 ml-4"></div>
                    )}
                    {!pending && (
                      <>
                        <img className="h-16 w-16 rounded-full"
                          src={
                            sessionData && sessionData.image
                              ? sessionData.image
                              : avatar
                          }
                          alt={""}
                        />

                        <span className="-mt-6 ml-4 shadow-lg absolute h-5 w-5 bg-brand-green rounded-full border-4 border-[#FEFEFE]"></span>
                      </>
                    )}
                  </button>

                  <CSSTransition
                    in={open}
                    timeout={350}
                    classNames="display"
                    unmountOnExit
                  >
                    <div
                      style={{ zIndex: 999999 }}
                      className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48"
                    >
                      <div className=" bg-white rounded-md shadow dark-mode:bg-gray-800">
                        <button
                          className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                          onClick={createOrganization}
                        >
                          Create Organization
                        </button>
                        {/** 
                        <button
                          className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                          onClick={()=>navigation("/trivia")}
                        >
                          Trivia Module
                        </button>
                    */}

                        <button
                          className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                          onClick={() => {
                            logout();
                            dispatch(resetHasFetched());
                            navigation("/");
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </CSSTransition>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
