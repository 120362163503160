import { useForm } from "react-hook-form";
import { callerPending, resetHasFetched, startFetch, stopFetch } from "../../redux/feature/callerSlice";
import { modalCallersForm, switchModalCallersForm } from "../../redux/feature/modalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { processActionService } from "../../utils/axios";
import { showToast } from "../../utils/common";
import { ColorFormInput } from "../form/ColorFormInput";
import { SelectFormInput } from "../form/SelectFormInput";
import { TextFormInput } from "../form/TextFormInput";
import { ToogleFormInput } from "../form/ToogleFormInput";

const upOptionData = () => [  ...Array(99).fill(0).map((e, i: number) => ({ value: i + 1, label: i + 1 }))]
const downOptionData = () => [  ...Array(99).fill(0).map((e, i: number) => ({ value: i + 1, label: i + 1 }))]
export const optionData = () => [
    { value: 1, label: 'Ding + code + number' },
    { value: 2, label: 'Ding + number + code' },
    { value: 3, label: 'Ding + code' },
    { value: 4, label: 'Ding + number' },
    { value: 5, label: 'code + number' },
    { value: 6, label: 'code' },
    { value: 7, label: 'number' },
    { value: 0, label: 'Ding' },
]

export const FormCallers = ({ data }: any) => {
    const dispatch = useAppDispatch();
    const pending = useAppSelector(callerPending);
    const modalCallers = useAppSelector(modalCallersForm);
    console.log(modalCallers?.caller);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: modalCallers?.caller ? {
            ...modalCallers?.caller,
            key_code_down: downOptionData().find((e: any) => e.value === modalCallers?.caller?.key_code_down),
            key_code_up: upOptionData().find((e: any) => e.value === modalCallers?.caller?.key_code_up),
            audio: optionData().find((e: any) => e.value === modalCallers?.caller?.audio),
            color:modalCallers?.caller?.primary_color
        } : {
            fullscreen: false,
        },
    });

    const onSubmit = (data: any) => {
        console.log(data);
        let caller = {
            name: data.name,
            key_code_up: data.key_code_up.value,
            key_code_down: data.key_code_down.value,
            primary_color: data.color,
            secondary_color: data.color,
            fullscreen: data.fullscreen,
            audio: data.audio.value,
        }
        dispatch(startFetch());
        processActionService(`/emission/${modalCallers?.emissionUUID}/callers${modalCallers?.caller ? "/" + modalCallers?.caller?.id : ""}`, modalCallers?.caller ? "put" : "post", caller)
            .then((response) => {
                if(response.message)showToast(response.message,'ok')
                dispatch(switchModalCallersForm({
                    show: false,
                    caller,
                    emissionUUID: modalCallers?.emissionUUID ?? ''
                }));
                dispatch(resetHasFetched());
                dispatch(stopFetch(null));
            })
            .catch(() => dispatch(stopFetch([])));
    };


    return (
        <section className="">

            <div className="caller-modal ">
                <div className="fixed right-28 mx-auto overflow-hidden rounded-xl bg-white px-8 py-10 text-left shadow-2xl w-11/12 md:w-1/2 lg:w-[21%] mt-10" style={{ zIndex: '100000000000000 !important' }}>
                    <div className="flex justify-between">
                        <h1 className="text-brand-blue font-semibold font-inter text-xl">
                            {modalCallers?.caller ? 'Set your caller' : 'Add caller'}
                        </h1>
                        <button onClick={() => {
                            dispatch(switchModalCallersForm({
                                show: false,
                                caller: null,
                                emissionUUID: modalCallers?.emissionUUID ?? ''
                            }));;
                        }} className="close-caller-modal">
                            <svg width="12" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.87888 6.96996C6.18015 7.27123 6.66859 7.27123 6.96985 6.96996C7.27111 6.6687 7.27111 6.18026 6.96985 5.879L5.65665 4.5658C5.34423 4.25338 5.34423 3.74685 5.65665 3.43443L6.96985 2.12123C7.27111 1.81997 7.27111 1.33153 6.96985 1.03027C6.66859 0.729006 6.18015 0.729006 5.87888 1.03027L4.56569 2.34347C4.25327 2.65589 3.74673 2.65589 3.43431 2.34347L2.12112 1.03027C1.81985 0.729006 1.33141 0.729006 1.03015 1.03027C0.72889 1.33153 0.72889 1.81997 1.03015 2.12123L2.34335 3.43443C2.65577 3.74685 2.65577 4.25338 2.34335 4.5658L1.03015 5.879C0.72889 6.18026 0.72889 6.6687 1.03015 6.96996C1.33141 7.27123 1.81985 7.27123 2.12112 6.96996L3.43431 5.65677C3.74673 5.34435 4.25327 5.34435 4.56569 5.65677L5.87888 6.96996Z" fill="#00274E" stroke="black" stroke-width="0.4" />
                            </svg>
                        </button>
                    </div>

                    <div className="pb-16">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextFormInput
                                disabled={pending}
                                errors={errors}
                                formControl={control}
                                label={"Name"}
                                placeholder={"Name of callers"}
                                name="name"
                            />

                            <div style={{ display: 'flex' }}>
                                <SelectFormInput
                                    disabled={pending}
                                    label={"Select code"}
                                    option={upOptionData()}
                                    formControl={control}
                                    name="key_code_up"
                                    errors={errors}
                                    placeholder={"Up"}
                                    style={{ width: 150 }}
                                />
                                <SelectFormInput
                                    disabled={pending}
                                    label={" "}
                                    option={downOptionData()}
                                    formControl={control}
                                    name="key_code_down"
                                    errors={errors}
                                    placeholder={"Down"}
                                    style={{ marginLeft: 10, width: 150 }}
                                />
                            </div>

                            <ColorFormInput
                                disabled={pending}
                                errors={errors}
                                formControl={control}
                                label={"Color"}
                                placeholder={"Color"}
                                name="color"
                            />



                            <div className="flex gap-3 py-8">
                                <div style={{ display: 'flex' }}>
                                    <SelectFormInput
                                        disabled={pending}
                                        label={"Audio"}
                                        option={optionData()}
                                        formControl={control}
                                        name="audio"
                                        errors={errors}
                                        style={{ marginLeft: 10, width: 200 }}
                                    />

                                    <ToogleFormInput
                                        disabled={pending}
                                        errors={errors}
                                        formControl={control}
                                        label={"Fullscreen"}
                                        placeholder={"Fullscreen"}
                                        name="fullscreen"
                                    />

                                </div>


                            </div>
                            <div className="mt-1 flex justify-end">
                                <button className="bg-brand-green py-2 px-5 rounded-lg text-white font-inter text-base">Apply</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    )
}