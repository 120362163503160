import { toast } from "react-toastify";

export const showToast = (message: string, type: string = "info") => {
  let opt: any = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  if (type === "info") toast.info(message, opt);
  if (type === "error") toast.error(message, opt);
  if (type === "ok") toast.success(message, opt);
};


 
/*
value.content.items.forEach((element: any) => {
          if (element.source === 'image') this.reel.push(ISlideType.IMAGE);
          if (element.source === 'video') this.reel.push(ISlideType.VIDEO);
        });

        if (value.trivia.loaded && this.configsData?.modules?.trivia.enable)
          this.reel = updateReel(
            this.configsData?.modules?.trivia.repeat,
            ISlideType.TRIVIA,
            this.reel
          );
        if (
          value.a_day_like_today.loaded &&
          this.configsData?.modules?.a_day_like_today.enable
        )
          this.reel = updateReel(
            this.configsData?.modules?.a_day_like_today.repeat,
            ISlideType.LIKETODAY,
            this.reel
          );
        if (
          value.did_you_know.loaded &&
          this.configsData?.modules?.did_you_know.enable
        )
          this.reel = updateReel(
            this.configsData?.modules?.did_you_know.repeat,
            ISlideType.DIDYOUNOW,
            this.reel
          );
        if (value.movie.loaded && this.configsData?.modules?.movie.enable)
          this.reel = updateReel(
            this.configsData?.modules?.movie.repeat,
            ISlideType.MOVIE,
            this.reel
          );
        if (value.shows.loaded && this.configsData?.modules?.show.enable)
          this.reel = updateReel(
            this.configsData?.modules?.show.repeat,
            ISlideType.SHOWS,
            this.reel
          );
        if (value.review.loaded && this.configsData?.modules?.review.enable)
          this.reel = updateReel(
            this.configsData?.modules?.review.repeat,
            ISlideType.REVIEWS,
            this.reel
          );
*/