import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../store";

// Define a type for the slice state

export interface ISport {
  status: string;
  name: string;
  uuid: string;
}

interface SportState {
  sportList: ISport[];
  Sport: ISport;
  pending: boolean;
  hasFetched: boolean;
}

// Define the initial state using that type
const initialState: SportState = {
  sportList: [],
  pending: false,
  hasFetched: false,
  Sport: null!,
};

export const sportSlice = createSlice({
  name: "Sport",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      state.pending = true;
    },

    stopFetch: (state, action: PayloadAction<ISport[] | any>) => {
      if (action.payload) state.sportList = action.payload;
      state.pending = false;
      state.hasFetched = true;
    },

    selectedFetch: (state, action: PayloadAction<ISport>) => {
      state.Sport = action.payload;
    },
  },
});

export const { startFetch, stopFetch, selectedFetch } = sportSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const sportList = (state: RootState) => state.sportSlice.sportList;
export const sportPending = (state: RootState) => state.sportSlice.pending;
export const sportHasFetched = (state: RootState) =>
  state.sportSlice.hasFetched;

export default sportSlice.reducer;
