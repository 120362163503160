import { Controller } from "react-hook-form";
import { getMessageByType } from "../../utils/formRules";

export const TextFormInput = ({
  formControl,
  errors,
  label,
  name,
  placeholder,
  disabled = false,
}: any) => (
  <Controller
    name={name}
    control={formControl}
    rules={{ required: true }}
    render={({ field }) => (
      <div className="relative">
        <label className="text-brand-blue font-inter text-sm font-medium">
          {label}
        </label>
        <input
          placeholder={placeholder}
          disabled={disabled}
          {...field}
          className="mt-2 pl-4 bg-brand-gray-100 w-full text-base font-inter font-medium h-12 rounded-lg"
        />
        {errors && errors[name] ? (
          <span className="absolute right-2 mt-4 bg-brand-lightred py-1.5 px-3 rounded font-inter text-sm font-medium text-brand-red ">
            {getMessageByType(errors[name].type)}
          </span>
        ) : (
          ""
        )}
      </div>
    )}
  />
);
