import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 
import type { RootState } from "../store";

// Define a type for the slice state
export interface IRange {
  start: string;
  end: string;
}
export interface IData {
  days: string[];
  range_hour: IRange[];
  date_range: IRange;
}
export interface ISlide {
  date: IData;
  thumb: string;
  md5: string;
  uuid: string;
  position: number;
  repeat: number;
  age: number;
}

interface SlideState {
  slideList: ISlide[];
  pending: boolean;
  hasFetched: boolean;
}

// Define the initial state using that type
const initialState: SlideState = {
  slideList: [],

  pending: false,
  hasFetched: false,
};

export const slideSlice = createSlice({
  name: "slide",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      state.slideList = [];
      state.pending = true;
    },
    stopFetch: (state, action: PayloadAction<ISlide[] | any>) => {
      state.pending = false;
      if (action.payload) {
        state.slideList = action.payload;
        state.hasFetched = true;
      }
    },
    resetHasFetched: (state) => {
      state.hasFetched = false;
    },
  },
});

export const { startFetch, stopFetch, resetHasFetched } = slideSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const slideList = (state: RootState) => state.slideSlice.slideList;
export const slidePending = (state: RootState) => state.slideSlice.pending;
export const slideHasFetched = (state: RootState) =>
  state.slideSlice.hasFetched;

export default slideSlice.reducer;
