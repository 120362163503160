// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const URL_SITE = process.env.REACT_APP_PUBLIC_URL;
const ROOTS_AUTH = "/web";
const ROOT = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/iniciar-sesion"),
};

export const PATH_PAGE = {
  root: ROOT,
  
  organization: path(ROOT, "organization"),
  trivia: path(ROOT, "trivia"),


  
  


  page403: "/403",
  page404: "/recurso-no-encontrado",
  page500: "/500",
};
