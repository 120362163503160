import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { processActionService } from "../../utils/axios";
import { fromStorage, toStorage } from "../../utils/storage";
import type { RootState } from "../store";

// Define a type for the slice state

export interface IIndustry {
  name: string;
  uuid: string;
}

export interface ICountry {
  name: string;
  uuid: string;
}

export interface IOrganization {
  country: string;
  industry: string;
  name: string;
  uuid: string;
}

interface OrganizationState {
  organizationList: IOrganization[];
  pending: boolean;
  hasFetched: boolean;

  industryList: IIndustry[];
  countryList: ICountry[];
}

// Define the initial state using that type
const initialState: OrganizationState = {
  organizationList: [],
  pending: false,
  hasFetched: false,

  countryList: [],
  industryList: [],
};

export const organizationSlice = createSlice({
  name: "organization",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      state.organizationList = [];
      state.pending = true;
    },

    stopFetch: (state, action: PayloadAction<IOrganization[] | any>) => {
      state.pending = false;
      if (action.payload) {
        state.organizationList = action.payload;
        state.hasFetched = true;
      }
    },

    inicializeCountry: (state, action: PayloadAction<ICountry[]>) => {
      if (action.payload) {
        state.countryList = action.payload;
      }
    },

    inicializeIndustry: (state, action: PayloadAction<IIndustry[]>) => {
      if (action.payload) {
        state.industryList = action.payload;
      }
    },

    resetHasFetched: (state) => {
      state.hasFetched = false;
    },
  },
});

export const { startFetch, stopFetch,resetHasFetched, inicializeCountry, inicializeIndustry } =
  organizationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const organizationList = (state: RootState) =>
  state.organizationSlice.organizationList;

export const organizationPending = (state: RootState) =>
  state.organizationSlice.pending;
export const organizationHasFetched = (state: RootState) =>
  state.organizationSlice.hasFetched;

export const countryList = (state: RootState) =>
  state.organizationSlice.countryList;

export const industryList = (state: RootState) =>
  state.organizationSlice.industryList;

export default organizationSlice.reducer;
