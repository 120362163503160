import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

export const DatepickerFormInput = ({
  formControl,
  errors,
  label,
  name,
  defaultValue,
  placeholder,
  option = [],
  keyValue = "value",
  keyName = "label",
  disabled = false,
}: any) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="mt-3 relative">
        <label className="text-brand-blue font-inter text-sm font-medium">
          {label}
        </label>

        <Controller
          name={name}
          control={formControl}
          rules={{ required: true }}
          render={({ field }) => (
            <ReactDatePicker
            className="mt-2 pl-4 bg-brand-gray-100 w-full text-base font-inter font-medium h-12 rounded-lg"

            autoComplete="off" selected={field.value}  {...field} />

          )}
        />
      </div>
    </>
  );
};
