import { Controller } from "react-hook-form";
import { getMessageByType } from "../../utils/formRules";

const COLORS = [
  {
    color: '#FFAA71',
    name: 'bg-brand-pink'
  },
  {
    color: '#FF6C86',
    name: 'bg-brand-skyblue'
  },
  {
    color: '#3DA4FE',
    name: 'bg-brand-blue-100'
  },
  {
    color: '#393880',
    name: 'bg-brand-lightgreen'
  }
]
export const ColorFormInput = ({
  formControl,
  errors,
  label,
  name,
  placeholder,
  disabled = false,
}: any) => (
  <Controller
    name={name}
    control={formControl}
    rules={{ required: true }}
    render={({ field }) => (
      <div className="relative  pt-4">
        <label className="text-brand-blue font-inter text-sm font-medium">
          {label}
        </label>
        <div className="  border-b-4 border-dashed border-brand-gray-100">

          <div className="flex justify-start gap-2">
            {COLORS.map((e: any, i: number) => <button type="button" key={i} onClick={() => field.onChange(e.color)} className={`pink h-6 w-6 rounded-lg `} style={{ background: e.color }}>
              <svg className={`pink-svg mx-auto  ${e.color === field.value ? '' : 'hidden'}`} width="14" height="12" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5639 0.464086L4.48008 7.0259C4.42786 7.08216 4.36432 7.12672 4.29363 7.15664C4.22295 7.18657 4.14672 7.20118 4.06998 7.19951C3.99324 7.19784 3.91773 7.17993 3.84841 7.14696C3.77909 7.11399 3.71755 7.0667 3.66783 7.00823L1.38668 4.32364C1.29333 4.21378 1.16017 4.14551 1.01649 4.13384C0.872813 4.12217 0.73038 4.16805 0.620529 4.26139C0.510677 4.35474 0.442405 4.48789 0.430732 4.63157C0.419059 4.77525 0.464941 4.91769 0.558283 5.02754L2.83943 7.71136C2.9886 7.88692 3.17328 8.02888 3.3813 8.12787C3.58932 8.22685 3.81597 8.28062 4.04629 8.28563C4.2766 8.29064 4.50537 8.24678 4.7175 8.15693C4.92963 8.06708 5.12031 7.93329 5.27696 7.76438L11.3608 1.20257C11.4548 1.09625 11.5034 0.957388 11.4964 0.815668C11.4893 0.673948 11.4271 0.540612 11.323 0.444163C11.2189 0.347714 11.0812 0.295803 10.9394 0.299526C10.7976 0.303249 10.6628 0.36231 10.5639 0.464086Z" fill="white" />
              </svg>
            </button>)}
            <input

              onChange={(e) => field.onChange(e.target.value)}
              value={field.value}
              type="color" style={{
                

                backgroundColor: `${field.value}`,

              }} />
          </div>
        </div>
        {errors && errors[name] ? (
          <span className="absolute right-2 mt-4 bg-brand-lightred py-1.5 px-3 rounded font-inter text-sm font-medium text-brand-red ">
            {getMessageByType(errors[name].type)}
          </span>
        ) : (
          ""
        )}
      </div>
    )}
  />
);
