import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../store";

interface IModuleSetting {
  enable: boolean;
  repeat: number;
}
export interface IModule {
  a_day_like_today: IModuleSetting;
  did_you_know: IModuleSetting;
 //movie: IModuleSetting;
  //show: IModuleSetting;
  trivia: IModuleSetting;
  instagram: IModuleSetting;
  review: IModuleSetting;

}
 

interface SettingState {
  audio: boolean;
  modules: IModule;
 
  pending: boolean;
  is_active: boolean;

  primary_color: string;
  secondary_color: string;
  third_color: string;
  background_color: string;
  notification_color_text: string;
  background_card: string;
  slider_color_active: string;
  slider_color_disabled: string;
  color_logo: boolean;
}

const initialState: SettingState = {
  modules: {
    a_day_like_today: { enable: false, repeat: 0 },   
    did_you_know: { enable: false, repeat: 0 },
    //movie: { enable: false, repeat: 0 },
    //show: { enable: false, repeat: 0 },
    trivia: { enable: false, repeat: 0 },
    instagram: { enable: false, repeat: 0 },
    review: { enable: false, repeat: 0 },

  },
  audio: false,
  is_active: true,
  pending: false,

  primary_color: "#00274E",
  secondary_color: "rgb(233 236 243)",
  third_color: "#A0C51E",
  background_color: "rgb(233 236 243)",
  notification_color_text: "#FFF",
  background_card: "#FFF",
  slider_color_active: "#224E99",
  slider_color_disabled: "#E4E7EE",
  color_logo: true,
};

export const moduleSlice = createSlice({
  name: "module",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      state.pending = true;
    },
    stopFetch: (state) => {
      state.pending = false;
    },
    updateAudio: (state, action: PayloadAction<boolean>) => {
      state.audio = action.payload;
    },
    updateModule: (
      state: any,
      action: PayloadAction<{
        uuid: string;
        value: any;
      }>
    ) => {
      state[action.payload.uuid] = action.payload.value;
    },
    inicializeModule: (state: any, action: PayloadAction<any>) => {
      state.modules = action.payload.modules;
      state.audio = action.payload.audio;
      state.is_active = action.payload.is_active;
      state.theme=action.payload.theme;
      state.primary_color = action.payload.primary_color;
      state.secondary_color = action.payload.secondary_color;
      state.third_color = action.payload.third_color;
      state.background_color = action.payload.background_color;
      state.notification_color_text = action.payload.notification_color_text;
      state.background_card = action.payload.background_card;
      state.color_logo = action.payload.color_logo;
      state.slider_color_active = action.payload.slider_color_active;
      state.slider_color_disabled = action.payload.slider_color_disabled;

    },

    updateTheme: (state: any, action: PayloadAction<any>) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  startFetch,
  stopFetch,
  updateModule,
  updateAudio,
  inicializeModule,
  updateTheme,
} = moduleSlice.actions;
export const modulePending = (state: RootState) => state.moduleSlice.pending;
export const moduleInfo = (state: RootState) => state.moduleSlice;

export default moduleSlice.reducer;
