import { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DatepickerFormInput } from "../form/DatepickerFormInput";
import axiosInstance, { asError } from "../../utils/axios";
import { showToast } from "../../utils/common";
import { UploadFormInput } from "../form/UploadFormInput";
import moment from "moment";
import { useAppDispatch } from "../../redux/hooks";
import { switchModalUploadForm } from "../../redux/feature/modalSlice";

const UploadModalEmission = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [percent, setPercent] = useState(0);
  const [pending, setPending] = useState(false);
  const [fileInfo, setFileInfo] = useState<any>([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      from: moment().toDate(),
      to: moment().add(5, "day").toDate(),
    },
  });

  const config = {
    onUploadProgress: (progressEvent: any) => {
      if (fileInfo) {
        let totalSize = 0
        fileInfo.forEach((e: any) => {
          totalSize += e.size
        });
        console.log(totalSize);
        console.log(progressEvent.loaded);

        let uploatedSize = progressEvent.loaded;
        let percent = (progressEvent.loaded * 100) / totalSize;
        setPercent(percent);
      }
    },
    headers: {
      //  "content-type": "multipart/form-data",
    },
  };

  const onSubmit = (data: any) => {

    let formData = new FormData();

    let dateKey = {
      days: [],
      range_hour: [{ start: "", end: "" }],
      date_range: {
        start: moment(data.from!).format("Y-MM-DD"),
        end: moment(data.to!).format("Y-MM-DD"),
      },
    };
    console.log(data.file);
    data.file.forEach((e: any) => {
      formData.append("file", e);
    });

    formData.append("emission_uuid", params.emision_uuid!);
    formData.append("date", JSON.stringify(dateKey));
    formData.append(
      "duration",
      "" + moment(data.to).diff(moment(data.from), "day", false)
    );
    formData.append("position", "0");
    setPending(true);
    axiosInstance
      .post(`emissions/${params.emision_uuid}/uploads`, formData, config)
      .then((response) => {
        setPending(false);
        dispatch(switchModalUploadForm(false));
      })
      .catch((e) => {
        setPercent(0);
        setPending(false);
        if (asError(e.code)) showToast(e.error);
      });
  };

  return (
    <>
      <section>

        <div
          className="absolute left-0 right-0 top-9 mx-auto w-[100vh]      rounded-3xl   z-50 "
          style={{ zIndex: "1000000 !important" }}
        >
          <div className="grid grid-cols-1 h-full  ">
            <div className="relative col-span-1 lg:col-span-8 xl:col-span-9">
              <div className="absolute top-16 left-0 w-full bg-white rounded-3xl p-8 z-50">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="border-b-4 border-dashed border-brand-gray-100 pb-9">
                    <h1 className="mt-5 text-brand-blue text-2xl font-bold">
                      Upload files
                    </h1>
                    <div className="flex gap-10 mt-3">
                      <DatepickerFormInput
                        disabled={pending}
                        errors={errors}
                        formControl={control}
                        label={"Desde"}
                        placeholder={"Desde"}
                        name="from"
                      />

                      <DatepickerFormInput
                        disabled={pending}
                        errors={errors}
                        formControl={control}
                        label={"Hasta"}
                        placeholder={"Hasta"}
                        name="to"
                      />
                    </div>
                    {fileInfo.length === 0 && (
                      <UploadFormInput
                        disabled={pending}
                        errors={errors}
                        formControl={control}
                        label={"Upload Files"}
                        placeholder={"Hasta"}
                        name="file"
                        setFileInfo={setFileInfo}
                      />
                    )}

                    {fileInfo.length > 0 && (
                      <div className="w-full mt-5 bg-brand-gray-100 border py-8 px-10 rounded-lg">
                        <h1 className="text-brand-blue text-base font-inter font-bold text-center">
                          {fileInfo.map((e: any) => e.name).join(", ")}
                        </h1>
                        <div className="mt-5 flex gap-3">
                          <p className="text-sm font-inter font-medium text-brand-blue">
                            {Math.floor(percent)}%
                          </p>
                          <div className="bg-brand-blue w-full h-2 rounded-full mt-1.5">
                            <div
                              className={`bg-brand-green   h-full rounded-l-full`}
                              style={{ width: percent + "%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex justify-between mt-5">
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(switchModalUploadForm(false));
                      }}
                    >
                      <svg
                        width="11"
                        height="20"
                        viewBox="0 0 11 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.13086 0.508814L0.975858 7.66381C0.350955 8.28891 -9.67205e-05 9.1366 -9.67591e-05 10.0205C-9.67978e-05 10.9044 0.350954 11.7521 0.975858 12.3771L8.13086 19.5321C8.44519 19.8357 8.8662 20.0037 9.30319 19.9999C9.74019 19.9961 10.1582 19.8209 10.4672 19.5118C10.7762 19.2028 10.9515 18.7848 10.9553 18.3478C10.9591 17.9108 10.7911 17.4898 10.4875 17.1755L3.33252 10.0205L10.4875 2.86548C10.6467 2.71173 10.7737 2.52783 10.861 2.32449C10.9484 2.12115 10.9944 1.90245 10.9963 1.68115C10.9982 1.45985 10.956 1.24038 10.8722 1.03556C10.7884 0.83073 10.6647 0.644643 10.5082 0.488155C10.3517 0.331667 10.1656 0.207912 9.96078 0.12411C9.75595 0.040309 9.53649 -0.00186016 9.31519 6.28549e-05C9.09389 0.00198587 8.87519 0.047963 8.67185 0.135311C8.46851 0.22266 8.2846 0.34963 8.13086 0.508814Z"
                          fill="#00274E"
                        />
                      </svg>
                    </button>
                    <button
                      disabled={pending}
                      type="submit"
                      className="flex gap-3 bg-brand-green py-2.5 px-7 rounded-lg text-base font-medium font-inter text-white"
                    >
                      Upload
                      {pending && (
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mt-1 ml-2"></div>
                      )}
                      {!pending && (
                        <svg
                          className="mt-1.5"
                          width="11"
                          height="14"
                          viewBox="0 0 11 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.14286 10.2143H7.85714V5.5H11L5.5 0L0 5.5H3.14286V10.2143ZM0 11.7857H11V13.3571H0V11.7857Z"
                            fill="#fff"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadModalEmission;
