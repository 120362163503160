import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../store";

// Define a type for the slice state

export interface ICaller {
  name: string;
  id: string;
  key_code_up: number;
  key_code_down: number;
  primary_color: string;
  secondary_color: string;
  fullscreen: boolean;
  audio: number;
}

interface CallerState {
  callerList: ICaller[];
  caller: ICaller;
  pending: boolean;
  hasFetched: boolean;
}

// Define the initial state using that type
const initialState: CallerState = {
  callerList: [],
  pending: false,
  hasFetched: false,
  caller: null!,
};

export const CallerSlice = createSlice({
  name: "Caller",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      state.pending = true;
    },

    stopFetch: (state, action: PayloadAction<ICaller[] | any>) => {
      if (action.payload) state.callerList = action.payload;
      state.pending = false;
      state.hasFetched = true;
    },

    selectedFetch: (state, action: PayloadAction<ICaller>) => {
      state.caller = action.payload;
    },

    resetHasFetched: (state) => {
      state.hasFetched = false;
    },
  },
});

export const { startFetch, stopFetch, resetHasFetched, selectedFetch } = CallerSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const callerList = (state: RootState) => state.callerSlice.callerList;
export const callerPending = (state: RootState) => state.callerSlice.pending;
export const callerHasFetched = (state: RootState) =>
  state.callerSlice.hasFetched; 
export const callerOne = (state: RootState) => state.callerSlice.caller;

export default CallerSlice.reducer;
