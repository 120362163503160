import jwtDecode from "jwt-decode";
// routes
import { PATH_AUTH } from "../routes/paths";
//
import axios from "./axios";
import { cleanStorage, fromStorage, toStorage } from "./storage";

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

 

const setSession = (accessToken) => {
  if (accessToken) {
    toStorage("DOBLED-TOKEN", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`; 
  } else {
    cleanStorage("DOBLED-TOKEN");
    cleanStorage("DOBLED-ADMIN-USERSESION");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setUserSession = (user) => {
  if (user) {
    toStorage("DOBLED-ADMIN-USERSESION", JSON.stringify(user));
  } else {
    cleanStorage("DOBLED-ADMIN-USERSESION");
    cleanStorage("DOBLED-TOKEN");
    delete axios.defaults.headers.common.Authorization;
  }
};

const getUserSession = () => {
  try {
    if (fromStorage("DOBLED-ADMIN-USERSESION")) {
      return JSON.parse(fromStorage("DOBLED-ADMIN-USERSESION"));
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const OPEN_PAY_ID = process.env.REACT_APP_OPEN_PAY_ID;
export const OPEN_PAY_APIKEY = process.env.REACT_APP_OPEN_PAY_APIKEY;
export const INITIALICE_OPENPAY = () => {
  cleanStorage("email");
};
export { isValidToken, setSession, setUserSession, getUserSession };
