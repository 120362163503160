import { Controller } from "react-hook-form";
import { getMessageByType } from "../../utils/formRules";


export const ToogleFormInput = ({
  formControl,
  errors,
  label,
  name,
  placeholder,
  disabled = false,
}: any) => (
  <Controller
    name={name}
    control={formControl}
 
    render={({ field }) => (
      <div className="relative" style={{ marginTop: 10 }} >

        <label className="text-brand-blue font-inter text-sm font-medium">
          {label}
        </label>
        <label htmlFor="fullscreen" className="flex items-center cursor-pointer   rounded-lg px-2" style={{ marginTop: 10 }}>
          <div className="relative"  >
            <input {...field} onChange={(s) => {
              field.onChange(!field.value);
            }} checked={field.value} type="checkbox" id="fullscreen" className="sr-only" />
            <div className="block bg-white w-8 h-4 rounded-full"></div>
            <div className="dot absolute left-0 top-0 bg-brand-blue w-4 h-4 rounded-full transition"></div>
          </div>
        </label>
        
      </div>
    )}
  />
);
