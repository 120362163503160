import { configureStore } from "@reduxjs/toolkit";
// ...
import modalSlice from "./feature/modalSlice";
import organizationSlice from "./feature/organizationSlice";
import emissionSlice from "./feature/emissionSlice";
import newspapperSlice from "./feature/newspapperSlice";
import sportSlice from "./feature/sportSlice";
import slideSlice from "./feature/slideSlice";
import triviaSlice from "./feature/triviaSlice";
import callerSlice from "./feature/callerSlice";
import moduleSlice from "./feature/moduleSlice";

export const store = configureStore({
  reducer: {
    modalSlice,
    organizationSlice,
    emissionSlice,
    newspapperSlice,
    triviaSlice,
    sportSlice,
    slideSlice,
    callerSlice,
    moduleSlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
