import { toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue, get } from "firebase/database";

export const showToast = (message: string, type: string = "info") => {
  let opt: any = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  if (type === "info") toast.info(message, opt);
  if (type === "error") toast.error(message, opt);
  if (type === "ok") toast.success(message, opt);
};

const firebaseConfig = {
  apiKey: "AIzaSyDtfN9ZZsQc2Qq9Eevf9oYdB48oulMrxC8",
  authDomain: "dobled-6aee2.firebaseapp.com",
  projectId: "dobled-6aee2",
  storageBucket: "dobled-6aee2.appspot.com",
  messagingSenderId: "375492882225",
  appId: "1:375492882225:web:df2e69b6a97bb6a8c4ca10"
};
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

export function updateDeviceInfo(emissionID: string, deviceID: string, dataPresentation: any) {
  const presentation = {
    ...dataPresentation
  }

  const colectionRef = ref(db, 'emission/' + emissionID);
  get(colectionRef)
    .then((snapshot: any) => {
      const value = snapshot.val()
      if (snapshot.exists()) {
        let device: any = {}
        if (value[deviceID]) {
          device = {
            [deviceID]: {
              ...value[deviceID],
              presentation
            }
          }
        }
        let dataForm = {
          ...value,
          ...device
        };
        set(colectionRef, dataForm).then((response) => { });
      }
    })
    .catch((error) => { });
}


export function updateEmissionData(emissionID: string, dataEmission: any) {

  const colectionRef = ref(db, 'emission/' + emissionID);
  get(colectionRef)
    .then((snapshot: any) => {
      const value = snapshot.val()
      if (snapshot.exists()) {
        let dataForm = {
          ...value,
          ...dataEmission
        };
        set(colectionRef, dataForm).then((response) => { });
      } else {
        let dataForm = {
          ...value,
          ...dataEmission
        };
        set(colectionRef, dataForm).then((response) => { });
      }
    })
    .catch((error) => { });
}


export function deleteDeviceFromEmission(emissionID: string, idDevice: any) {

  const colectionRef = ref(db, 'emission/' + emissionID);
  get(colectionRef)
    .then((snapshot: any) => {
      const value = snapshot.val()
      console.log(value);
      if (value[idDevice])
        delete value[idDevice]
      if (snapshot.exists()) {
        let dataForm = {
          ...value,
        };
        set(colectionRef, dataForm).then((response) => { });
      }
    })
    .catch((error) => { });
}






export function readEmissionData(emissionID: string, callback: Function) {
  const colectionRef = ref(db, 'emission/' + emissionID);
  onValue(colectionRef, (snapshot: any) => {
    const data = snapshot.val();
    callback(data);
  });
}



/*
value.content.items.forEach((element: any) => {
          if (element.source === 'image') this.reel.push(ISlideType.IMAGE);
          if (element.source === 'video') this.reel.push(ISlideType.VIDEO);
        });

        if (value.trivia.loaded && this.configsData?.modules?.trivia.enable)
          this.reel = updateReel(
            this.configsData?.modules?.trivia.repeat,
            ISlideType.TRIVIA,
            this.reel
          );
        if (
          value.a_day_like_today.loaded &&
          this.configsData?.modules?.a_day_like_today.enable
        )
          this.reel = updateReel(
            this.configsData?.modules?.a_day_like_today.repeat,
            ISlideType.LIKETODAY,
            this.reel
          );
        if (
          value.did_you_know.loaded &&
          this.configsData?.modules?.did_you_know.enable
        )
          this.reel = updateReel(
            this.configsData?.modules?.did_you_know.repeat,
            ISlideType.DIDYOUNOW,
            this.reel
          );
        if (value.movie.loaded && this.configsData?.modules?.movie.enable)
          this.reel = updateReel(
            this.configsData?.modules?.movie.repeat,
            ISlideType.MOVIE,
            this.reel
          );
        if (value.shows.loaded && this.configsData?.modules?.show.enable)
          this.reel = updateReel(
            this.configsData?.modules?.show.repeat,
            ISlideType.SHOWS,
            this.reel
          );
        if (value.review.loaded && this.configsData?.modules?.review.enable)
          this.reel = updateReel(
            this.configsData?.modules?.review.repeat,
            ISlideType.REVIEWS,
            this.reel
          );
*/