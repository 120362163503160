import { useState } from "react";

import avatar from "../../assets/images/avatar.png";

export const BackgroundBlur = () => {
  const [open, setOpen] = useState(false);
  return (
    <section className="overlay" style={{position:'absolute'}}>
      {/*<!-- header -->*/}
      <header>
        <div className="bg-white">
          <div className="w-full px-4 lg:px-8 2xl:max-w-screen-2xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-12">
              <div className="col-span-1 lg:col-span-8 xl:col-span-9 flex justify-between w-full 2xl:max-w-screen-2xl mx-auto px-4 lg:px-8 py-4">
                <div className="flex flex-col sm:flex-row gap-10">
                  {/*<!-- logo -->*/}
                  <div className="flex gap-3 mt-3">
                    <svg
                      width="34"
                      height="41"
                      viewBox="0 0 34 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.6896 -0.000244141L16.4245 7.56541L19.0332 10.078L20.3404 5.53184L31.0899 16.7053L27.8061 30.5798L26.1162 29.2502L24.8417 28.2493L22.6167 26.4912L21.2115 25.3857L15.8503 21.1645L17.1042 16.7996L14.3555 14.4485L12.0054 22.284L20.3983 28.4781L21.8287 29.5369L24.1069 31.2203L25.4066 32.1783L29.0909 34.9019L33.4129 16.0274L18.6896 -0.000244141Z"
                        fill="#00274E"
                      />
                      <path
                        d="M20.0789 36.3202L4.97654 26.7714L10.7243 8.81753L13.2144 11.0238L14.9306 12.5392L17.642 14.9322L19.1826 16.2926L24.1218 20.6679L23.0826 24.6967L25.2805 26.4931L26.9564 19.7603L20.021 13.2899L18.514 11.8791L15.8699 9.41042L14.1929 7.84743L8.62352 2.64307L0 28.3624L21.6671 40.9998L23.654 33.0299L21.3525 31.3922L20.0789 36.3202Z"
                        fill="#A0C51E"
                      />
                    </svg>
                    <svg
                      className="mt-2"
                      width="101"
                      height="22"
                      viewBox="0 0 101 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.48549 1.92432C14.3948 1.92432 18.5067 5.81871 18.5067 11.4563C18.5067 17.0659 14.3406 20.9874 8.32116 20.9874H0.396973V1.92432H8.48549ZM4.6994 17.3656H8.59379C11.7805 17.3656 14.1221 14.9688 14.1221 11.4834C14.1221 7.96993 11.6712 5.54701 8.40333 5.54701H4.6994V17.3656Z"
                        fill="#00274E"
                      />
                      <path
                        d="M35.7145 13.6615C35.7145 18.1273 32.5558 21.1506 27.8715 21.1506C23.1611 21.1506 20.0015 18.1273 20.0015 13.6615C20.0015 9.16858 23.1601 6.19946 27.8715 6.19946C32.5558 6.19946 35.7145 9.16765 35.7145 13.6615ZM24.2227 13.7156C24.2227 16.1124 25.6932 17.7463 27.8715 17.7463C30.0227 17.7463 31.5213 16.1124 31.5213 13.7156C31.5213 11.3189 30.0236 9.68491 27.8715 9.68491C25.6932 9.68585 24.2227 11.3189 24.2227 13.7156Z"
                        fill="#00274E"
                      />
                      <path
                        d="M54.3409 13.7436C54.3409 18.1823 51.6444 21.1505 47.5324 21.1505C45.4895 21.1505 43.8836 20.3335 42.8211 18.863V20.9871H38.627V0.780273H42.8211V8.48691C43.8556 7.01636 45.4625 6.19938 47.4503 6.19938C51.5622 6.19938 54.3409 9.22265 54.3409 13.7436ZM50.0917 13.6063C50.0917 11.2646 48.6211 9.57559 46.4699 9.57559C44.3187 9.57559 42.8211 11.2366 42.8211 13.6063C42.8211 16.0302 44.3187 17.6641 46.4699 17.6641C48.6211 17.6641 50.0917 16.0031 50.0917 13.6063Z"
                        fill="#00274E"
                      />
                      <path
                        d="M57.2251 20.9871V0.780273H61.4192V20.9871H57.2251Z"
                        fill="#00274E"
                      />
                      <path
                        d="M79.091 14.9416H68.5787C69.0688 16.7931 70.4852 17.8827 72.3648 17.8827C73.7531 17.8827 75.0603 17.3384 76.0677 16.331L78.274 18.5643C76.8035 20.1983 74.6523 21.1516 72.0109 21.1516C67.3266 21.1516 64.3584 18.1563 64.3584 13.7176C64.3584 9.19667 67.4358 6.20142 71.9016 6.20142C77.0491 6.19955 79.4449 9.60376 79.091 14.9416ZM75.1695 12.4636C75.1154 10.53 73.8624 9.27698 71.9287 9.27698C70.0772 9.27698 68.8242 10.53 68.4974 12.4636H75.1695Z"
                        fill="#00274E"
                      />
                      <path
                        d="M90.0368 1.92432C95.9461 1.92432 100.058 5.81871 100.058 11.4563C100.058 17.0659 95.8919 20.9874 89.8724 20.9874H81.9482V1.92432H90.0368ZM86.2507 17.3656H90.1451C93.3317 17.3656 95.6734 14.9688 95.6734 11.4834C95.6734 7.96993 93.2225 5.54701 89.9546 5.54701H86.2507V17.3656Z"
                        fill="#A0C51E"
                      />
                    </svg>
                  </div>
                  <button className="mt-2 flex gap-1.5 bg-brand-green px-4 py-2.5 h-12 text-lg font-inter font-medium text-white rounded-xl">
                    <svg
                      className="mt-2.5"
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 4.16667H3.19167L6.175 1.175L5 0L0 5L5 10L6.175 8.825L3.19167 5.83333H11.5V4.16667Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                    Back to dashboard
                  </button>
                </div>
                <div className="flex justify-end gap-6">
                  <button className="absolute top-10 right-28 sm:top-0 sm:right-0 sm:relative">
                    <svg
                      width="20"
                      height="24"
                      viewBox="0 0 20 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.84615 24C11.2062 24 12.3077 22.8985 12.3077 21.5385H7.38462C7.38462 22.8985 8.48615 24 9.84615 24ZM17.2308 16.6154V10.4615C17.2308 6.67692 15.2185 3.52 11.6923 2.68308V1.84615C11.6923 0.824615 10.8677 0 9.84615 0C8.82462 0 8 0.824615 8 1.84615V2.68308C4.47385 3.52 2.46154 6.67692 2.46154 10.4615V16.6154L0 19.0769V20.3077H19.6923V19.0769L17.2308 16.6154Z"
                        fill="#00274E"
                      />
                    </svg>
                    <span className="-mt-8  shadow-lg absolute h-5 w-5 bg-brand-green rounded-full border-4 border-[#FEFEFE]"></span>
                  </button>
                  <div className="relative " x-data="{ open: false }">
                    <button className="relative">
                      <img className="h-16 w-16" src={avatar} alt="" />
                      <span className="-mt-6 ml-4 shadow-lg absolute h-5 w-5 bg-brand-green rounded-full border-4 border-[#FEFEFE]"></span>
                    </button>
                    <div className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
                      <div className="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                        <a
                          className="block px-4 py-2 mt-2 font-inter text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          href="#"
                        >
                          Link #1
                        </a>
                        <a
                          className="block px-4 py-2 mt-2 font-inter text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          href="#"
                        >
                          Link #2
                        </a>
                        <a
                          className="block px-4 py-2 mt-2 font-inter text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          href="#"
                        >
                          Link #3
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/*<!-- dashboard -->*/}
      <section>
        <div className="bg-brand-gray w-full h-full pb-8">
          <div className="w-full px-4 lg:px-8 2xl:max-w-screen-2xl mx-auto pt-4">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-5 mt-6">
              <div className="col-span-1 lg:col-span-8 xl:col-span-9 space-y-6">
                <div className="bg-white rounded-3xl p-4 block lg:hidden">
                  <div className="py-1.5 w-4/12 px-2 bg-brand-green rounded-lg">
                    <h2 className="font-inter text-sm font-medium text-white text-center">
                      Emission
                    </h2>
                  </div>
                  <div className="relative" x-data="{ open: false }">
                    <button className="flex flex-row justify-between items-center w-full py-2 mt-2 font-inter text-2xl font-bold text-brand-blue">
                      <span>Fikiri Studio</span>
                      <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        className="inline w-6 h-6 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <div className="absolute bg-white left-0 w-full border shadow-lg rounded-lg z-50">
                      <div className="">
                        <a
                          className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                          href="#"
                        >
                          Siyira Studio
                        </a>
                        <a
                          className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                          href="#"
                        >
                          Dikiri Studio
                        </a>
                        <a
                          className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                          href="#"
                        >
                          QWssi Studio
                        </a>
                      </div>
                    </div>
                  </div>
                  <button className="mt-3 w-full flex justify-between bg-brand-gray-100 py-3 px-5 rounded-xl font-inter text-brand-green font-medium">
                    Upload files
                    <svg
                      className="mt-1.5"
                      width="11"
                      height="14"
                      viewBox="0 0 11 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.14286 10.2143H7.85714V5.5H11L5.5 0L0 5.5H3.14286V10.2143ZM0 11.7857H11V13.3571H0V11.7857Z"
                        fill="#A0C51E"
                      />
                    </svg>
                  </button>
                </div>
                {/*<!-- cards -->*/}
                <div className="space-y-10">
                  {/*<!-- 0 -->*/}
                  <div className="flex gap-7">
                    <button className="">
                      <svg
                        className="hover-svg"
                        width="8"
                        height="32"
                        viewBox="0 0 8 32"
                        fill="#D9D9D9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="" />
                        <circle cx="4" cy="16" r="4" fill="" />
                        <circle cx="4" cy="28" r="4" fill="" />
                      </svg>
                    </button>
                    <div className="relative bg-white rounded-2xl p-3 w-full flex gap-4 ">
                      <div className="w-1/3 xl:w-[21%] h-24 bg-brand-gray-100 border rounded-lg">
                        <svg
                          className="mx-auto mt-8"
                          width="40"
                          height="42"
                          viewBox="0 0 40 42"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M27.8537 3.39839C27.8537 5.275 26.3322 6.79643 24.4553 6.79643C22.5787 6.79643 21.0569 5.275 21.0569 3.39839C21.0569 1.52143 22.5787 0 24.4553 0C26.3322 0 27.8537 1.52143 27.8537 3.39839Z"
                            fill="#00274E"
                          />
                          <path
                            d="M18.3586 6.98692C18.3586 8.67596 16.9891 10.0451 15.3004 10.0451C13.6114 10.0451 12.2422 8.67596 12.2422 6.98692C12.2422 5.29787 13.6114 3.92871 15.3004 3.92871C16.9891 3.92871 18.3586 5.29787 18.3586 6.98692Z"
                            fill="#00274E"
                          />
                          <path
                            d="M13.2364 14.9949C13.5816 16.456 12.6767 17.9201 11.2156 18.2654C9.75447 18.6102 8.29036 17.7056 7.94515 16.2445C7.60028 14.783 8.50494 13.3189 9.96635 12.9741C11.4275 12.6289 12.8916 13.5339 13.2364 14.9949Z"
                            fill="#00274E"
                          />
                          <path
                            d="M14.9116 25.2626C14.9116 26.5764 13.8467 27.6414 12.5328 27.6414C11.2189 27.6414 10.1541 26.5765 10.1541 25.2626C10.1541 23.9487 11.2189 22.8838 12.5328 22.8838C13.8467 22.8838 14.9116 23.9487 14.9116 25.2626Z"
                            fill="#00274E"
                          />
                          <path
                            d="M21.0037 29.5158C22.044 29.9467 22.5378 31.1392 22.107 32.1795C21.6762 33.2198 20.4837 33.7139 19.4433 33.2832C18.4031 32.852 17.9089 31.6595 18.3397 30.6192C18.7705 29.579 19.9634 29.0848 21.0037 29.5159"
                            fill="#00274E"
                          />
                          <path
                            d="M30.0968 29.4612C31.0348 29.4849 31.7762 30.2643 31.7529 31.2022C31.7296 32.1405 30.9498 32.8819 30.0119 32.8586C29.0736 32.8349 28.3322 32.0556 28.3555 31.1173C28.3792 30.1793 29.1585 29.4379 30.0968 29.4612Z"
                            fill="#00274E"
                          />
                          <path
                            d="M38.79 24.6536C38.79 25.404 38.1816 26.0125 37.4309 26.0125C36.6805 26.0125 36.072 25.404 36.072 24.6536C36.072 23.9029 36.6805 23.2944 37.4309 23.2944C38.1816 23.2944 38.79 23.9029 38.79 24.6536Z"
                            fill="#00274E"
                          />
                          <path
                            d="M38.9026 13.9109C37.5881 13.9109 37.5881 15.9497 38.9026 15.9497C40.2171 15.9494 40.2171 13.9109 38.9026 13.9109Z"
                            fill="#00274E"
                          />
                          <path
                            d="M33.7793 7.21465C34.6559 7.21465 34.6559 5.85547 33.7793 5.85547C32.9027 5.8558 32.9027 7.21465 33.7793 7.21465Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.156001 41.3103C0.148004 41.3149 0.139341 41.3179 0.130677 41.3196C0.12168 41.3223 0.112351 41.3236 0.102688 41.3236C0.0733655 41.3236 0.0500409 41.3156 0.0333808 41.2996C0.0163871 41.2829 0.00805664 41.2606 0.00805664 41.233C0.00805664 41.2056 0.0163868 41.1837 0.0333808 41.1677C0.0500409 41.151 0.0733655 41.1423 0.102688 41.1423C0.112351 41.1423 0.121681 41.1437 0.130677 41.1463C0.13934 41.1483 0.148003 41.1513 0.156001 41.1557V41.1916C0.148004 41.1857 0.139341 41.1817 0.130677 41.1797C0.12268 41.177 0.114016 41.1757 0.105352 41.1757C0.0893586 41.1757 0.0766965 41.181 0.0680334 41.1916C0.0590367 41.2016 0.0547051 41.2153 0.0547051 41.233C0.0547051 41.251 0.0590368 41.265 0.0680334 41.2756C0.0766965 41.2856 0.0893586 41.2903 0.105352 41.2903C0.114016 41.2903 0.122679 41.2889 0.130677 41.2863C0.13934 41.2836 0.148003 41.2796 0.156001 41.2743V41.3103Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.288459 41.2249C0.284794 41.2233 0.281128 41.2219 0.277796 41.2209C0.274131 41.2203 0.270132 41.2196 0.265801 41.2196C0.255139 41.2196 0.246475 41.2233 0.240477 41.2303C0.235146 41.2366 0.23248 41.2463 0.23248 41.2596V41.3196H0.191162V41.1889H0.23248V41.2103C0.237811 41.2023 0.243809 41.1966 0.25114 41.1929C0.258137 41.1886 0.266467 41.1863 0.276464 41.1863H0.280462H0.288459L0.288459 41.2249Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.439748 41.2543V41.2662H0.342454C0.343121 41.2762 0.346453 41.2836 0.351784 41.2889C0.357782 41.2936 0.366779 41.2956 0.378441 41.2956C0.386438 41.2956 0.394768 41.2942 0.403765 41.2916C0.413428 41.2889 0.423091 41.2849 0.433087 41.2796V41.3116C0.423091 41.3152 0.413428 41.3179 0.403765 41.3196C0.393769 41.3222 0.384106 41.3236 0.374443 41.3236C0.350452 41.3236 0.331792 41.3179 0.318464 41.3062C0.305802 41.2939 0.299805 41.2766 0.299805 41.2543C0.299805 41.2329 0.305802 41.2166 0.318464 41.2049C0.331792 41.1926 0.349452 41.1863 0.371777 41.1863C0.392103 41.1863 0.40843 41.1926 0.421091 41.2049C0.43342 41.2176 0.439751 41.2339 0.439751 41.2543L0.439748 41.2543ZM0.397098 41.2396C0.397098 41.2316 0.394432 41.2256 0.389101 41.2209C0.384436 41.2166 0.378772 41.2143 0.371775 41.2143C0.363778 41.2143 0.357113 41.2166 0.351782 41.2209C0.346451 41.2256 0.343119 41.2316 0.342452 41.2396H0.397098Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.529888 41.2609C0.520891 41.2609 0.514227 41.2629 0.509895 41.2662C0.50523 41.2689 0.503231 41.2729 0.503231 41.2782C0.503231 41.2836 0.504897 41.2882 0.508562 41.2916C0.511894 41.2942 0.516892 41.2956 0.523224 41.2956C0.531221 41.2956 0.537885 41.2929 0.543216 41.2876C0.548548 41.2816 0.551213 41.2742 0.551213 41.2662V41.2609L0.529888 41.2609ZM0.593863 41.2449V41.3196H0.551213V41.2996C0.545882 41.3076 0.539551 41.3139 0.532553 41.3182C0.525223 41.3216 0.516893 41.3236 0.507229 41.3236C0.493901 41.3236 0.482572 41.3196 0.473909 41.3116C0.465912 41.3036 0.461914 41.2936 0.461914 41.2809C0.461914 41.2649 0.466579 41.2536 0.476576 41.2463C0.487238 41.2393 0.503898 41.2356 0.527223 41.2356H0.551214V41.2329C0.551214 41.2259 0.548548 41.2209 0.543217 41.2183C0.537886 41.2156 0.529889 41.2143 0.519226 41.2143C0.510229 41.2143 0.5019 41.2153 0.493902 41.2169C0.486571 41.2189 0.479574 41.2216 0.472577 41.2249V41.1929C0.48124 41.1903 0.490236 41.1886 0.499233 41.1876C0.508896 41.1869 0.518226 41.1863 0.527222 41.1863C0.550213 41.1863 0.567207 41.1913 0.577869 41.2009C0.588532 41.2099 0.593863 41.2246 0.593863 41.2449V41.2449Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.67739 41.1516V41.1889H0.72004V41.2183H0.67739V41.2742C0.67739 41.2806 0.678723 41.2849 0.681388 41.2876C0.684054 41.2896 0.688719 41.2902 0.69605 41.2902H0.717375V41.3195H0.681388C0.664395 41.3195 0.6524 41.3165 0.645402 41.3102C0.639071 41.3032 0.636071 41.2912 0.636071 41.2742V41.2183H0.614746V41.1889H0.636071V41.1516L0.67739 41.1516Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.877004 41.2543V41.2662H0.77971C0.780376 41.2762 0.783709 41.2836 0.78904 41.2889C0.795038 41.2936 0.804034 41.2956 0.815697 41.2956C0.823694 41.2956 0.832024 41.2942 0.841021 41.2916C0.850684 41.2889 0.860347 41.2849 0.870343 41.2796V41.3116C0.860347 41.3152 0.850684 41.3179 0.841021 41.3196C0.831025 41.3222 0.821362 41.3236 0.811699 41.3236C0.787708 41.3236 0.769048 41.3179 0.75572 41.3062C0.743058 41.2939 0.737061 41.2766 0.737061 41.2543C0.737061 41.2329 0.743058 41.2166 0.75572 41.2049C0.769048 41.1926 0.786708 41.1863 0.809033 41.1863C0.829359 41.1863 0.845686 41.1926 0.858347 41.2049C0.870676 41.2176 0.877007 41.2339 0.877007 41.2543L0.877004 41.2543ZM0.834354 41.2396C0.834354 41.2316 0.831688 41.2256 0.826357 41.2209C0.821692 41.2166 0.816028 41.2143 0.809031 41.2143C0.801034 41.2143 0.794369 41.2166 0.789038 41.2209C0.783706 41.2256 0.780375 41.2316 0.779708 41.2396H0.834354Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.997556 41.2077V41.1384H1.03887V41.3197H0.997556V41.301C0.991225 41.3084 0.984561 41.314 0.977563 41.3184C0.971232 41.3217 0.963235 41.3237 0.953572 41.3237C0.937578 41.3237 0.92425 41.3177 0.913587 41.305C0.903591 41.2917 0.898926 41.275 0.898926 41.2544C0.898926 41.2351 0.903591 41.2191 0.913587 41.2064C0.92425 41.1931 0.937578 41.1864 0.953572 41.1864C0.963235 41.1864 0.971232 41.1884 0.977563 41.1917C0.98456 41.1954 0.991225 41.2007 0.997556 41.2077V41.2077ZM0.969567 41.293C0.97823 41.293 0.984895 41.29 0.98956 41.2837C0.994891 41.2767 0.997557 41.267 0.997557 41.2544C0.997557 41.2421 0.994891 41.2327 0.98956 41.2264C0.984895 41.2194 0.978231 41.2157 0.969567 41.2157C0.96057 41.2157 0.953573 41.2194 0.948242 41.2264C0.943577 41.2327 0.941577 41.2421 0.941577 41.2544C0.941577 41.267 0.943577 41.2767 0.948242 41.2837C0.953573 41.29 0.96057 41.293 0.969567 41.293Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.2319 41.293C1.24057 41.293 1.24723 41.29 1.2519 41.2837C1.25723 41.2767 1.25989 41.267 1.25989 41.2544C1.25989 41.2421 1.25723 41.2327 1.2519 41.2264C1.24723 41.2194 1.24057 41.2157 1.2319 41.2157C1.22291 41.2157 1.21591 41.2194 1.21058 41.2264C1.20591 41.2327 1.20392 41.2421 1.20392 41.2544C1.20392 41.267 1.20591 41.2767 1.21058 41.2837C1.21591 41.29 1.22291 41.293 1.2319 41.293ZM1.20392 41.2077C1.20991 41.2007 1.21658 41.1954 1.22391 41.1917C1.23091 41.1884 1.2389 41.1864 1.2479 41.1864C1.26389 41.1864 1.27656 41.1931 1.28655 41.2064C1.29721 41.2191 1.30254 41.2351 1.30254 41.2544C1.30254 41.275 1.29721 41.2917 1.28655 41.305C1.27655 41.3177 1.26389 41.3237 1.2479 41.3237C1.2389 41.3237 1.23091 41.3217 1.22391 41.3184C1.21658 41.314 1.20991 41.3084 1.20392 41.301V41.3197H1.1626V41.1384H1.20392V41.2077Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.31616 41.189H1.35748L1.39347 41.2783L1.42279 41.189H1.46411L1.40946 41.3316C1.40413 41.3466 1.3978 41.3569 1.3908 41.3622C1.38347 41.3682 1.37381 41.3716 1.36148 41.3716H1.33749V41.3436H1.35082C1.35781 41.3436 1.36281 41.3422 1.36548 41.3396C1.36881 41.3376 1.37148 41.3336 1.37348 41.3276L1.37481 41.3249L1.31616 41.189Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.57251 41.1891H1.61383V41.3197H1.57251V41.1891ZM1.57251 41.1384H1.61383V41.1731H1.57251V41.1384Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.78467 41.2396V41.3196H1.74335V41.2582C1.74335 41.2476 1.74269 41.2403 1.74202 41.2356C1.74202 41.2313 1.74102 41.2283 1.73935 41.2263C1.73836 41.2236 1.73602 41.2216 1.73269 41.2196C1.73003 41.2179 1.72669 41.2169 1.72336 41.2169C1.71536 41.2169 1.7087 41.2206 1.70337 41.2276C1.69804 41.2339 1.69537 41.2433 1.69537 41.2556V41.3196H1.65405V41.1889H1.69537V41.2076C1.70137 41.2006 1.70803 41.1953 1.71536 41.1916C1.72236 41.1883 1.73036 41.1863 1.73935 41.1863C1.75435 41.1863 1.76534 41.1913 1.77267 41.2009C1.78067 41.2099 1.78467 41.2229 1.78467 41.2396L1.78467 41.2396Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.95489 41.2543V41.2662H1.85759C1.85826 41.2762 1.86159 41.2836 1.86692 41.2889C1.87292 41.2936 1.88192 41.2956 1.89358 41.2956C1.90157 41.2956 1.90991 41.2942 1.9189 41.2916C1.92856 41.2889 1.93823 41.2849 1.94822 41.2796V41.3116C1.93823 41.3152 1.92856 41.3179 1.9189 41.3196C1.90891 41.3222 1.89924 41.3236 1.88958 41.3236C1.86559 41.3236 1.84693 41.3179 1.8336 41.3062C1.82094 41.2939 1.81494 41.2766 1.81494 41.2543C1.81494 41.2329 1.82094 41.2166 1.8336 41.2049C1.84693 41.1926 1.86459 41.1863 1.88691 41.1863C1.90724 41.1863 1.92357 41.1926 1.93623 41.2049C1.94856 41.2176 1.95489 41.2339 1.95489 41.2543L1.95489 41.2543ZM1.91223 41.2396C1.91223 41.2316 1.90957 41.2256 1.90424 41.2209C1.89957 41.2166 1.89391 41.2143 1.88691 41.2143C1.87891 41.2143 1.87225 41.2166 1.86692 41.2209C1.86159 41.2256 1.85826 41.2316 1.85759 41.2396H1.91223Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.97971 41.189H2.09434V41.2183L2.02503 41.2903H2.09434V41.3196H1.97705V41.2903L2.04636 41.2183H1.97972L1.97971 41.189Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.11838 41.189H2.23301V41.2183L2.1637 41.2903H2.23301V41.3196H2.11572V41.2903L2.18503 41.2183H2.11839L2.11838 41.189Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.32286 41.2609C2.31386 41.2609 2.3072 41.2629 2.30286 41.2662C2.2982 41.2689 2.2962 41.2729 2.2962 41.2782C2.2962 41.2836 2.29787 41.2882 2.30153 41.2916C2.30486 41.2942 2.30986 41.2956 2.31619 41.2956C2.32419 41.2956 2.33085 41.2929 2.33619 41.2876C2.34152 41.2816 2.34418 41.2742 2.34418 41.2662V41.2609L2.32286 41.2609ZM2.38683 41.2449V41.3196H2.34418V41.2996C2.33885 41.3076 2.33252 41.3139 2.32552 41.3182C2.31819 41.3216 2.30986 41.3236 2.3002 41.3236C2.28687 41.3236 2.27554 41.3196 2.26688 41.3116C2.25888 41.3036 2.25488 41.2936 2.25488 41.2809C2.25488 41.2649 2.25955 41.2536 2.26954 41.2463C2.28021 41.2393 2.29687 41.2356 2.32019 41.2356H2.34418V41.2329C2.34418 41.2259 2.34152 41.2209 2.33619 41.2183C2.33085 41.2156 2.32286 41.2143 2.31219 41.2143C2.3032 41.2143 2.29487 41.2153 2.28687 41.2169C2.27954 41.2189 2.27254 41.2216 2.26555 41.2249V41.1929C2.27421 41.1903 2.28321 41.1886 2.2922 41.1876C2.30186 41.1869 2.3112 41.1863 2.32019 41.1863C2.34318 41.1863 2.36018 41.1913 2.37084 41.2009C2.3815 41.2099 2.38683 41.2246 2.38683 41.2449V41.2449Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.56724 41.2609C2.55824 41.2609 2.55158 41.2629 2.54725 41.2662C2.54258 41.2689 2.54058 41.2729 2.54058 41.2782C2.54058 41.2836 2.54225 41.2882 2.54592 41.2916C2.54925 41.2942 2.55425 41.2956 2.56058 41.2956C2.56857 41.2956 2.57524 41.2929 2.58057 41.2876C2.5859 41.2816 2.58857 41.2742 2.58857 41.2662V41.2609L2.56724 41.2609ZM2.63122 41.2449V41.3196H2.58857V41.2996C2.58324 41.3076 2.5769 41.3139 2.56991 41.3182C2.56258 41.3216 2.55425 41.3236 2.54458 41.3236C2.53125 41.3236 2.51993 41.3196 2.51126 41.3116C2.50327 41.3036 2.49927 41.2936 2.49927 41.2809C2.49927 41.2649 2.50393 41.2536 2.51393 41.2463C2.52459 41.2393 2.54125 41.2356 2.56458 41.2356H2.58857V41.2329C2.58857 41.2259 2.5859 41.2209 2.58057 41.2183C2.57524 41.2156 2.56724 41.2143 2.55658 41.2143C2.54758 41.2143 2.53925 41.2153 2.53126 41.2169C2.52392 41.2189 2.51693 41.2216 2.50993 41.2249V41.1929C2.51859 41.1903 2.52759 41.1886 2.53659 41.1876C2.54625 41.1869 2.55558 41.1863 2.56458 41.1863C2.58757 41.1863 2.60456 41.1913 2.61522 41.2009C2.62589 41.2099 2.63122 41.2246 2.63122 41.2449V41.2449Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.76697 41.2249C2.76331 41.2233 2.75964 41.2219 2.75631 41.2209C2.75265 41.2203 2.74865 41.2196 2.74432 41.2196C2.73365 41.2196 2.72499 41.2233 2.71899 41.2303C2.71366 41.2366 2.711 41.2463 2.711 41.2596V41.3196H2.66968V41.1889H2.711V41.2103C2.71633 41.2023 2.72232 41.1966 2.72966 41.1929C2.73665 41.1886 2.74498 41.1863 2.75498 41.1863H2.75898H2.76697L2.76697 41.2249Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.87695 41.2077V41.1384H2.91827V41.3197H2.87695V41.301C2.87062 41.3084 2.86396 41.314 2.85696 41.3184C2.85063 41.3217 2.84263 41.3237 2.83297 41.3237C2.81697 41.3237 2.80364 41.3177 2.79298 41.305C2.78299 41.2917 2.77832 41.275 2.77832 41.2544C2.77832 41.2351 2.78299 41.2191 2.79298 41.2064C2.80364 41.1931 2.81697 41.1864 2.83297 41.1864C2.84263 41.1864 2.85063 41.1884 2.85696 41.1917C2.86395 41.1954 2.87062 41.2007 2.87695 41.2077V41.2077ZM2.84896 41.293C2.85762 41.293 2.86429 41.29 2.86895 41.2837C2.87429 41.2767 2.87695 41.267 2.87695 41.2544C2.87695 41.2421 2.87429 41.2327 2.86895 41.2264C2.86429 41.2194 2.85763 41.2157 2.84896 41.2157C2.83996 41.2157 2.83297 41.2194 2.82764 41.2264C2.82297 41.2327 2.82097 41.2421 2.82097 41.2544C2.82097 41.267 2.82297 41.2767 2.82764 41.2837C2.83297 41.29 2.83996 41.293 2.84896 41.293Z"
                            fill="#00274E"
                          />
                          <path
                            d="M3.08916 41.2543V41.2662H2.99187C2.99253 41.2762 2.99587 41.2836 3.0012 41.2889C3.0072 41.2936 3.01619 41.2956 3.02786 41.2956C3.03585 41.2956 3.04418 41.2942 3.05318 41.2916C3.06284 41.2889 3.0725 41.2849 3.0825 41.2796V41.3116C3.0725 41.3152 3.06284 41.3179 3.05318 41.3196C3.04318 41.3222 3.03352 41.3236 3.02386 41.3236C2.99987 41.3236 2.98121 41.3179 2.96788 41.3062C2.95522 41.2939 2.94922 41.2766 2.94922 41.2543C2.94922 41.2329 2.95522 41.2166 2.96788 41.2049C2.98121 41.1926 2.99887 41.1863 3.02119 41.1863C3.04152 41.1863 3.05784 41.1926 3.07051 41.2049C3.08283 41.2176 3.08917 41.2339 3.08917 41.2543L3.08916 41.2543ZM3.04651 41.2396C3.04651 41.2316 3.04385 41.2256 3.03852 41.2209C3.03385 41.2166 3.02819 41.2143 3.02119 41.2143C3.01319 41.2143 3.00653 41.2166 3.0012 41.2209C2.99586 41.2256 2.99253 41.2316 2.99187 41.2396H3.04651Z"
                            fill="#00274E"
                          />
                          <path
                            d="M3.12061 41.1384H3.16192V41.3197H3.12061V41.1384Z"
                            fill="#00274E"
                          />
                          <path
                            d="M3.20239 41.1891H3.24371V41.3197H3.20239V41.1891ZM3.20239 41.1384H3.24371V41.1731H3.20239V41.1384Z"
                            fill="#00274E"
                          />
                          <path
                            d="M3.34263 41.2609C3.33364 41.2609 3.32697 41.2629 3.32264 41.2662C3.31797 41.2689 3.31597 41.2729 3.31597 41.2782C3.31597 41.2836 3.31764 41.2882 3.32131 41.2916C3.32464 41.2942 3.32964 41.2956 3.33597 41.2956C3.34396 41.2956 3.35063 41.2929 3.35596 41.2876C3.36129 41.2816 3.36396 41.2742 3.36396 41.2662V41.2609L3.34263 41.2609ZM3.40661 41.2449V41.3196H3.36396V41.2996C3.35863 41.3076 3.35229 41.3139 3.3453 41.3182C3.33797 41.3216 3.32964 41.3236 3.31997 41.3236C3.30665 41.3236 3.29532 41.3196 3.28665 41.3116C3.27866 41.3036 3.27466 41.2936 3.27466 41.2809C3.27466 41.2649 3.27932 41.2536 3.28932 41.2463C3.29998 41.2393 3.31664 41.2356 3.33997 41.2356H3.36396V41.2329C3.36396 41.2259 3.36129 41.2209 3.35596 41.2183C3.35063 41.2156 3.34263 41.2143 3.33197 41.2143C3.32297 41.2143 3.31464 41.2153 3.30665 41.2169C3.29932 41.2189 3.29232 41.2216 3.28532 41.2249V41.1929C3.29398 41.1903 3.30298 41.1886 3.31198 41.1876C3.32164 41.1869 3.33097 41.1863 3.33997 41.1863C3.36296 41.1863 3.37995 41.1913 3.39061 41.2009C3.40128 41.2099 3.40661 41.2246 3.40661 41.2449V41.2449Z"
                            fill="#00274E"
                          />
                          <path
                            d="M3.52872 41.1891H3.57004V41.317C3.57004 41.3347 3.56537 41.348 3.55671 41.357C3.54871 41.3667 3.53705 41.3717 3.52206 41.3717H3.50073V41.3437H3.5074C3.51539 41.3437 3.52072 41.3417 3.52339 41.3384C3.52672 41.3347 3.52872 41.3277 3.52872 41.317L3.52872 41.1891ZM3.52872 41.1384H3.57004V41.1731H3.52872V41.1384Z"
                            fill="#00274E"
                          />
                          <path
                            d="M3.66905 41.2609C3.66005 41.2609 3.65339 41.2629 3.64906 41.2662C3.64439 41.2689 3.64239 41.2729 3.64239 41.2782C3.64239 41.2836 3.64406 41.2882 3.64772 41.2916C3.65105 41.2942 3.65605 41.2956 3.66238 41.2956C3.67038 41.2956 3.67705 41.2929 3.68238 41.2876C3.68771 41.2816 3.69037 41.2742 3.69037 41.2662V41.2609L3.66905 41.2609ZM3.73302 41.2449V41.3196H3.69037V41.2996C3.68504 41.3076 3.67871 41.3139 3.67171 41.3182C3.66438 41.3216 3.65605 41.3236 3.64639 41.3236C3.63306 41.3236 3.62173 41.3196 3.61307 41.3116C3.60507 41.3036 3.60107 41.2936 3.60107 41.2809C3.60107 41.2649 3.60574 41.2536 3.61574 41.2463C3.6264 41.2393 3.64306 41.2356 3.66638 41.2356H3.69037V41.2329C3.69037 41.2259 3.68771 41.2209 3.68238 41.2183C3.67705 41.2156 3.66905 41.2143 3.65839 41.2143C3.64939 41.2143 3.64106 41.2153 3.63306 41.2169C3.62573 41.2189 3.61873 41.2216 3.61174 41.2249V41.1929C3.6204 41.1903 3.6294 41.1886 3.63839 41.1876C3.64806 41.1869 3.65739 41.1863 3.66638 41.1863C3.68937 41.1863 3.70637 41.1913 3.71703 41.2009C3.72769 41.2099 3.73302 41.2246 3.73302 41.2449V41.2449Z"
                            fill="#00274E"
                          />
                          <path
                            d="M3.87405 41.1929V41.2249C3.86405 41.2216 3.85539 41.2189 3.84739 41.2169C3.8394 41.2153 3.8314 41.2143 3.8234 41.2143C3.81607 41.2143 3.81041 41.2153 3.80608 41.2169C3.80241 41.2189 3.80075 41.2219 3.80075 41.2263C3.80075 41.2289 3.80208 41.2313 3.80474 41.2329C3.80741 41.2349 3.81274 41.2363 3.82074 41.2369L3.8274 41.2383C3.84873 41.2409 3.86272 41.2456 3.87005 41.2516C3.87805 41.2579 3.88205 41.2676 3.88205 41.2809C3.88205 41.2952 3.87672 41.3059 3.86605 41.3129C3.85606 41.3199 3.84106 41.3236 3.82074 41.3236C3.81174 41.3236 3.80275 41.3226 3.79408 41.3209C3.78509 41.3192 3.77576 41.3169 3.76609 41.3142V41.2836C3.77409 41.2872 3.78242 41.2902 3.79142 41.2929C3.80008 41.2949 3.80908 41.2956 3.81807 41.2956C3.82607 41.2956 3.83174 41.2949 3.8354 41.2929C3.83973 41.2902 3.84206 41.2869 3.84206 41.2822C3.84206 41.2779 3.84073 41.2749 3.83807 41.2729C3.8354 41.2712 3.8294 41.2699 3.82074 41.2689H3.81408C3.79542 41.2662 3.78209 41.2619 3.77409 41.2556C3.76676 41.2496 3.76343 41.2396 3.76343 41.2263C3.76343 41.2129 3.76809 41.2033 3.77809 41.1969C3.78775 41.1899 3.80241 41.1863 3.82207 41.1863C3.82907 41.1863 3.83673 41.1869 3.84473 41.1876C3.85339 41.1886 3.86339 41.1903 3.87405 41.1929H3.87405Z"
                            fill="#00274E"
                          />
                          <path
                            d="M4.01639 41.1929V41.2249C4.00639 41.2216 3.99773 41.2189 3.98973 41.2169C3.98173 41.2153 3.97373 41.2143 3.96574 41.2143C3.95841 41.2143 3.95274 41.2153 3.94841 41.2169C3.94475 41.2189 3.94308 41.2219 3.94308 41.2263C3.94308 41.2289 3.94441 41.2313 3.94708 41.2329C3.94974 41.2349 3.95508 41.2363 3.96307 41.2369L3.96974 41.2383C3.99106 41.2409 4.00506 41.2456 4.01239 41.2516C4.02038 41.2579 4.02438 41.2676 4.02438 41.2809C4.02438 41.2952 4.01905 41.3059 4.00839 41.3129C3.99839 41.3199 3.9834 41.3236 3.96307 41.3236C3.95408 41.3236 3.94508 41.3226 3.93642 41.3209C3.92742 41.3192 3.91809 41.3169 3.90843 41.3142V41.2836C3.91642 41.2872 3.92475 41.2902 3.93375 41.2929C3.94241 41.2949 3.95141 41.2956 3.96041 41.2956C3.9684 41.2956 3.97407 41.2949 3.97773 41.2929C3.98207 41.2902 3.9844 41.2869 3.9844 41.2822C3.9844 41.2779 3.98307 41.2749 3.9804 41.2729C3.97773 41.2712 3.97174 41.2699 3.96307 41.2689H3.95641C3.93775 41.2662 3.92442 41.2619 3.91642 41.2556C3.90909 41.2496 3.90576 41.2396 3.90576 41.2263C3.90576 41.2129 3.91043 41.2033 3.92042 41.1969C3.93009 41.1899 3.94475 41.1863 3.96441 41.1863C3.9714 41.1863 3.97907 41.1869 3.98706 41.1876C3.99573 41.1886 4.00572 41.1903 4.01639 41.1929H4.01639Z"
                            fill="#00274E"
                          />
                          <path
                            d="M4.17695 41.2103C4.18228 41.2023 4.18828 41.1966 4.19561 41.1929C4.20261 41.1886 4.21061 41.1863 4.2196 41.1863C4.2346 41.1863 4.24559 41.1913 4.25292 41.2009C4.26092 41.2099 4.26492 41.2229 4.26492 41.2396V41.3196H4.2236V41.2516V41.2489V41.2436C4.2236 41.2349 4.22227 41.2283 4.2196 41.2236C4.21694 41.2193 4.21227 41.2169 4.20627 41.2169C4.19828 41.2169 4.19195 41.2206 4.18761 41.2276C4.18395 41.2339 4.18162 41.2433 4.18095 41.2556V41.3196H4.13963V41.2516C4.13963 41.2376 4.1383 41.2283 4.13563 41.2236C4.13297 41.2193 4.1283 41.2169 4.12231 41.2169C4.11431 41.2169 4.10798 41.2206 4.10365 41.2276C4.09898 41.2339 4.09698 41.2433 4.09698 41.2556V41.3196H4.05566V41.1889H4.09698V41.2076C4.10231 41.2006 4.10798 41.1953 4.11431 41.1916C4.12131 41.1883 4.12897 41.1863 4.13697 41.1863C4.14563 41.1863 4.15363 41.1886 4.16096 41.1929C4.16795 41.1976 4.17329 41.2033 4.17695 41.2103L4.17695 41.2103Z"
                            fill="#00274E"
                          />
                          <path
                            d="M4.30469 41.1891H4.34601V41.3197H4.30469V41.1891ZM4.30469 41.1384H4.34601V41.1731H4.30469V41.1384Z"
                            fill="#00274E"
                          />
                          <path
                            d="M4.51734 41.2396V41.3196H4.47602V41.2582C4.47602 41.2476 4.47535 41.2403 4.47469 41.2356C4.47469 41.2313 4.47369 41.2283 4.47202 41.2263C4.47102 41.2236 4.46869 41.2216 4.46536 41.2196C4.46269 41.2179 4.45936 41.2169 4.45603 41.2169C4.44803 41.2169 4.44137 41.2206 4.43603 41.2276C4.4307 41.2339 4.42804 41.2433 4.42804 41.2556V41.3196H4.38672V41.1889H4.42804V41.2076C4.43403 41.2006 4.4407 41.1953 4.44803 41.1916C4.45503 41.1883 4.46302 41.1863 4.47202 41.1863C4.48701 41.1863 4.49801 41.1913 4.50534 41.2009C4.51334 41.2099 4.51734 41.2229 4.51734 41.2396L4.51734 41.2396Z"
                            fill="#00274E"
                          />
                          <path
                            d="M4.68731 41.2543V41.2662H4.59001C4.59068 41.2762 4.59401 41.2836 4.59934 41.2889C4.60534 41.2936 4.61434 41.2956 4.626 41.2956C4.634 41.2956 4.64233 41.2942 4.65132 41.2916C4.66099 41.2889 4.67065 41.2849 4.68065 41.2796V41.3116C4.67065 41.3152 4.66099 41.3179 4.65132 41.3196C4.64133 41.3222 4.63166 41.3236 4.622 41.3236C4.59801 41.3236 4.57935 41.3179 4.56602 41.3062C4.55336 41.2939 4.54736 41.2766 4.54736 41.2543C4.54736 41.2329 4.55336 41.2166 4.56602 41.2049C4.57935 41.1926 4.59701 41.1863 4.61934 41.1863C4.63966 41.1863 4.65599 41.1926 4.66865 41.2049C4.68098 41.2176 4.68731 41.2339 4.68731 41.2543L4.68731 41.2543ZM4.64466 41.2396C4.64466 41.2316 4.64199 41.2256 4.63666 41.2209C4.63199 41.2166 4.62633 41.2143 4.61933 41.2143C4.61134 41.2143 4.60467 41.2166 4.59934 41.2209C4.59401 41.2256 4.59068 41.2316 4.59001 41.2396H4.64466Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.101294 41.3772V41.4052H0.0786367C0.0733054 41.4052 0.0693065 41.4062 0.0666417 41.4079C0.063976 41.4099 0.0626432 41.4132 0.0626432 41.4185V41.4278H0.0986299V41.4572H0.0626432V41.5585H0.0213252V41.4572H0V41.4278H0.0213252V41.4185C0.0213252 41.4045 0.0253237 41.3942 0.0333202 41.3879C0.0413172 41.3809 0.0536457 41.3772 0.0706393 41.3772L0.101294 41.3772Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.21717 41.4639C0.213504 41.4623 0.209839 41.4609 0.206507 41.4599C0.202842 41.4593 0.198843 41.4586 0.194512 41.4586C0.18385 41.4586 0.175186 41.4623 0.169188 41.4693C0.163857 41.4756 0.161191 41.4853 0.161191 41.4986V41.5586H0.119873V41.428H0.161191V41.4493C0.166522 41.4413 0.17252 41.4356 0.179851 41.432C0.186848 41.4276 0.195178 41.4253 0.205175 41.4253H0.209173H0.21717L0.21717 41.4639Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.300244 41.4546C0.290248 41.4546 0.282918 41.4583 0.277586 41.4653C0.272922 41.4716 0.270922 41.4809 0.270922 41.4933C0.270922 41.5059 0.272922 41.5156 0.277586 41.5226C0.282918 41.5289 0.290249 41.5319 0.300244 41.5319C0.308907 41.5319 0.315572 41.5289 0.320237 41.5226C0.325568 41.5156 0.328234 41.5059 0.328234 41.4933C0.328234 41.4809 0.325568 41.4716 0.320237 41.4653C0.315572 41.4583 0.308908 41.4546 0.300244 41.4546ZM0.300244 41.4253C0.322235 41.4253 0.339563 41.4316 0.352225 41.444C0.364553 41.4556 0.370884 41.4719 0.370884 41.4933C0.370884 41.5146 0.364553 41.5316 0.352225 41.5439C0.339563 41.5566 0.322236 41.5626 0.300244 41.5626C0.276919 41.5626 0.25926 41.5566 0.246931 41.5439C0.234269 41.5316 0.228271 41.5146 0.228271 41.4933C0.228271 41.4719 0.234269 41.4556 0.246931 41.444C0.25926 41.4316 0.276919 41.4253 0.300244 41.4253Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.523145 41.4493C0.528476 41.4413 0.534474 41.4356 0.541804 41.432C0.548802 41.4276 0.556798 41.4253 0.565795 41.4253C0.580789 41.4253 0.591785 41.4303 0.599115 41.44C0.607112 41.449 0.61111 41.4619 0.61111 41.4786V41.5586H0.569792V41.4906V41.4879V41.4826C0.569792 41.4739 0.568459 41.4673 0.565794 41.4626C0.563128 41.4583 0.558463 41.4559 0.552466 41.4559C0.544469 41.4559 0.538138 41.4596 0.533806 41.4666C0.530141 41.4729 0.527808 41.4823 0.527142 41.4946V41.5586H0.485824V41.4906C0.485824 41.4766 0.484491 41.4673 0.481825 41.4626C0.47916 41.4583 0.474495 41.4559 0.468497 41.4559C0.4605 41.4559 0.454169 41.4596 0.449838 41.4666C0.445173 41.4729 0.443173 41.4823 0.443173 41.4946V41.5586H0.401855V41.428H0.443173V41.4466C0.448505 41.4396 0.45417 41.4343 0.4605 41.4306C0.467497 41.4273 0.475161 41.4253 0.483157 41.4253C0.491821 41.4253 0.499817 41.4276 0.507148 41.432C0.514146 41.4366 0.519477 41.4423 0.523142 41.4493L0.523145 41.4493Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.778952 41.3906V41.4279H0.821603V41.4573H0.778952V41.5132C0.778952 41.5196 0.780285 41.5239 0.782951 41.5266C0.785616 41.5286 0.790281 41.5292 0.797612 41.5292H0.818937V41.5586H0.782951C0.765957 41.5586 0.753962 41.5556 0.746964 41.5492C0.740633 41.5422 0.737634 41.5302 0.737634 41.5132V41.4573H0.716309V41.4279H0.737634V41.3906L0.778952 41.3906Z"
                            fill="#00274E"
                          />
                          <path
                            d="M0.978519 41.4785V41.5585H0.937201V41.4985C0.937201 41.4872 0.936535 41.4792 0.935868 41.4745C0.935868 41.4702 0.934869 41.4672 0.933202 41.4652C0.932203 41.4625 0.92987 41.4605 0.926538 41.4585C0.923873 41.4568 0.920541 41.4558 0.917208 41.4558C0.909211 41.4558 0.902547 41.4595 0.897215 41.4665C0.891884 41.4728 0.889218 41.4822 0.889218 41.4945V41.5585H0.8479V41.3772H0.889218V41.4465C0.895216 41.4395 0.90188 41.4342 0.909211 41.4305C0.916209 41.4272 0.924205 41.4252 0.933202 41.4252C0.948196 41.4252 0.959192 41.4302 0.966522 41.4398C0.974519 41.4488 0.978517 41.4618 0.978517 41.4785L0.978519 41.4785Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.14849 41.4933V41.5053H1.05119C1.05186 41.5153 1.05519 41.5226 1.06052 41.5279C1.06652 41.5326 1.07552 41.5346 1.08718 41.5346C1.09518 41.5346 1.10351 41.5333 1.11251 41.5306C1.12217 41.5279 1.13183 41.5239 1.14183 41.5186V41.5506C1.13183 41.5542 1.12217 41.5569 1.11251 41.5586C1.10251 41.5612 1.09285 41.5626 1.08318 41.5626C1.05919 41.5626 1.04053 41.5569 1.0272 41.5452C1.01454 41.5329 1.00854 41.5156 1.00854 41.4933C1.00854 41.4719 1.01454 41.4556 1.0272 41.444C1.04053 41.4316 1.05819 41.4253 1.08052 41.4253C1.10084 41.4253 1.11717 41.4316 1.12983 41.444C1.14216 41.4566 1.14849 41.4729 1.14849 41.4933L1.14849 41.4933ZM1.10584 41.4786C1.10584 41.4706 1.10317 41.4646 1.09784 41.4599C1.09318 41.4556 1.08751 41.4533 1.08051 41.4533C1.07252 41.4533 1.06585 41.4556 1.06052 41.4599C1.05519 41.4646 1.05186 41.4706 1.05119 41.4786H1.10584Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.2644 41.3853H1.31505L1.37769 41.5039V41.3853H1.42035V41.5585H1.37103L1.30706 41.4386V41.5585H1.26441L1.2644 41.3853Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.52583 41.4546C1.51583 41.4546 1.5085 41.4583 1.50317 41.4653C1.49851 41.4716 1.49651 41.4809 1.49651 41.4933C1.49651 41.5059 1.49851 41.5156 1.50317 41.5226C1.5085 41.5289 1.51583 41.5319 1.52583 41.5319C1.53449 41.5319 1.54116 41.5289 1.54582 41.5226C1.55115 41.5156 1.55382 41.5059 1.55382 41.4933C1.55382 41.4809 1.55115 41.4716 1.54582 41.4653C1.54116 41.4583 1.53449 41.4546 1.52583 41.4546ZM1.52583 41.4253C1.54782 41.4253 1.56515 41.4316 1.57781 41.444C1.59014 41.4556 1.59647 41.4719 1.59647 41.4933C1.59647 41.5146 1.59014 41.5316 1.57781 41.5439C1.56515 41.5566 1.54782 41.5626 1.52583 41.5626C1.50251 41.5626 1.48485 41.5566 1.47252 41.5439C1.45985 41.5316 1.45386 41.5146 1.45386 41.4933C1.45386 41.4719 1.45986 41.4556 1.47252 41.444C1.48485 41.4316 1.50251 41.4253 1.52583 41.4253Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.62573 41.5079V41.428H1.66705V41.4413V41.468V41.4893V41.5119C1.66772 41.5166 1.66905 41.5196 1.67105 41.5213C1.67271 41.5249 1.67505 41.5276 1.67771 41.5293C1.68038 41.5303 1.68338 41.5306 1.68704 41.5306C1.69571 41.5306 1.70237 41.5276 1.70704 41.5213C1.71237 41.5143 1.71503 41.5046 1.71503 41.492V41.428H1.75635V41.5586H1.71503V41.5399C1.7087 41.5473 1.70204 41.5529 1.69504 41.5573C1.68771 41.5606 1.68038 41.5626 1.67238 41.5626C1.65705 41.5626 1.64573 41.5583 1.63773 41.5493C1.62973 41.5396 1.62573 41.5259 1.62573 41.5079L1.62573 41.5079Z"
                            fill="#00274E"
                          />
                          <path
                            d="M1.92798 41.4786V41.5586H1.88666V41.4973C1.88666 41.4866 1.886 41.4793 1.88533 41.4746C1.88533 41.4703 1.88433 41.4673 1.88267 41.4653C1.88167 41.4626 1.87933 41.4606 1.876 41.4586C1.87334 41.4569 1.87 41.4559 1.86667 41.4559C1.85867 41.4559 1.85201 41.4596 1.84668 41.4666C1.84135 41.4729 1.83868 41.4823 1.83868 41.4946V41.5586H1.79736V41.428H1.83868V41.4466C1.84468 41.4396 1.85134 41.4343 1.85867 41.4306C1.86567 41.4273 1.87367 41.4253 1.88266 41.4253C1.89766 41.4253 1.90866 41.4303 1.91599 41.44C1.92398 41.449 1.92798 41.4619 1.92798 41.4786L1.92798 41.4786Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.05151 41.3853H2.12615C2.14814 41.3853 2.16514 41.3903 2.1768 41.3999C2.18913 41.4099 2.19546 41.4236 2.19546 41.4412C2.19546 41.4599 2.18913 41.4742 2.1768 41.4839C2.16514 41.4939 2.14814 41.4985 2.12615 41.4985H2.09683V41.5585H2.05151V41.3853ZM2.09683 41.4172V41.4652H2.12215C2.13015 41.4652 2.13615 41.4636 2.14081 41.4599C2.14614 41.4556 2.14881 41.4492 2.14881 41.4412C2.14881 41.4332 2.14614 41.4276 2.14081 41.4239C2.13615 41.4196 2.13015 41.4172 2.12215 41.4172L2.09683 41.4172Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.32264 41.4639C2.31897 41.4623 2.31531 41.4609 2.31198 41.4599C2.30831 41.4593 2.30431 41.4586 2.29998 41.4586C2.28932 41.4586 2.28066 41.4623 2.27466 41.4693C2.26933 41.4756 2.26666 41.4853 2.26666 41.4986V41.5586H2.22534V41.428H2.26666V41.4493C2.27199 41.4413 2.27799 41.4356 2.28532 41.432C2.29232 41.4276 2.30065 41.4253 2.31064 41.4253H2.31464H2.32264L2.32264 41.4639Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.40571 41.4546C2.39572 41.4546 2.38839 41.4583 2.38306 41.4653C2.37839 41.4716 2.37639 41.4809 2.37639 41.4933C2.37639 41.5059 2.37839 41.5156 2.38306 41.5226C2.38839 41.5289 2.39572 41.5319 2.40571 41.5319C2.41438 41.5319 2.42104 41.5289 2.42571 41.5226C2.43104 41.5156 2.4337 41.5059 2.4337 41.4933C2.4337 41.4809 2.43104 41.4716 2.42571 41.4653C2.42104 41.4583 2.41438 41.4546 2.40571 41.4546ZM2.40571 41.4253C2.4277 41.4253 2.44503 41.4316 2.45769 41.444C2.47002 41.4556 2.47635 41.4719 2.47635 41.4933C2.47635 41.5146 2.47002 41.5316 2.45769 41.5439C2.44503 41.5566 2.42771 41.5626 2.40571 41.5626C2.38239 41.5626 2.36473 41.5566 2.3524 41.5439C2.33974 41.5316 2.33374 41.5146 2.33374 41.4933C2.33374 41.4719 2.33974 41.4556 2.3524 41.444C2.36473 41.4316 2.38239 41.4253 2.40571 41.4253Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.50748 41.4278H2.5488V41.5558C2.5488 41.5735 2.54413 41.5868 2.53547 41.5958C2.52747 41.6054 2.51581 41.6104 2.50082 41.6104H2.47949V41.5825H2.48616C2.49415 41.5825 2.49948 41.5805 2.50215 41.5771C2.50548 41.5735 2.50748 41.5665 2.50748 41.5558L2.50748 41.4278ZM2.50748 41.3772H2.5488V41.4119H2.50748V41.3772Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.71953 41.4933V41.5053H2.62224C2.62291 41.5153 2.62624 41.5226 2.63157 41.5279C2.63757 41.5326 2.64656 41.5346 2.65823 41.5346C2.66622 41.5346 2.67455 41.5333 2.68355 41.5306C2.69321 41.5279 2.70288 41.5239 2.71287 41.5186V41.5506C2.70288 41.5542 2.69321 41.5569 2.68355 41.5586C2.67355 41.5612 2.66389 41.5626 2.65423 41.5626C2.63024 41.5626 2.61158 41.5569 2.59825 41.5452C2.58559 41.5329 2.57959 41.5156 2.57959 41.4933C2.57959 41.4719 2.58559 41.4556 2.59825 41.444C2.61158 41.4316 2.62924 41.4253 2.65156 41.4253C2.67189 41.4253 2.68822 41.4316 2.70088 41.444C2.71321 41.4566 2.71954 41.4729 2.71954 41.4933L2.71953 41.4933ZM2.67688 41.4786C2.67688 41.4706 2.67422 41.4646 2.66889 41.4599C2.66422 41.4556 2.65856 41.4533 2.65156 41.4533C2.64356 41.4533 2.6369 41.4556 2.63157 41.4599C2.62624 41.4646 2.6229 41.4706 2.62224 41.4786H2.67688Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.85657 41.432V41.4666C2.85124 41.4623 2.84524 41.4593 2.83924 41.4573C2.83391 41.4556 2.82791 41.4546 2.82191 41.4546C2.81025 41.4546 2.80092 41.4583 2.79392 41.4653C2.78759 41.4716 2.78459 41.4809 2.78459 41.4933C2.78459 41.5059 2.78759 41.5156 2.79392 41.5226C2.80092 41.5289 2.81025 41.5319 2.82191 41.5319C2.82791 41.5319 2.83424 41.5313 2.84057 41.5293C2.84657 41.5276 2.8519 41.5249 2.85657 41.5213V41.5546C2.85024 41.5572 2.84357 41.5589 2.83657 41.5599C2.83024 41.5616 2.82358 41.5626 2.81658 41.5626C2.79326 41.5626 2.77526 41.5566 2.76194 41.5439C2.74861 41.5316 2.74194 41.5146 2.74194 41.4933C2.74194 41.4719 2.74861 41.4556 2.76194 41.444C2.77526 41.4316 2.79326 41.4253 2.81658 41.4253C2.82358 41.4253 2.83024 41.426 2.83657 41.4266C2.84357 41.4276 2.85024 41.4293 2.85657 41.432L2.85657 41.432Z"
                            fill="#00274E"
                          />
                          <path
                            d="M2.93789 41.3906V41.4279H2.98054V41.4573H2.93789V41.5132C2.93789 41.5196 2.93922 41.5239 2.94189 41.5266C2.94455 41.5286 2.94922 41.5292 2.95655 41.5292H2.97787V41.5586H2.94189C2.92489 41.5586 2.9129 41.5556 2.9059 41.5492C2.89957 41.5422 2.89657 41.5302 2.89657 41.5132V41.4573H2.87524V41.4279H2.89657V41.3906L2.93789 41.3906Z"
                            fill="#00274E"
                          />
                        </svg>
                      </div>
                      <div className="">
                        <p className="mt-3 lg:mt-6 text-base font-inter font-medium text-brand-blue">
                          This video is being processed.{" "}
                        </p>
                        <p className="mt-2 text-sm md:text-base font-inter font-medium text-brand-blue">
                          The video will be published once it is finished being
                          processed.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*<!-- 1 -->*/}
                  <div className="flex gap-7">
                    <button className="">
                      <svg
                        className="hover-svg"
                        width="8"
                        height="32"
                        viewBox="0 0 8 32"
                        fill="#D9D9D9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="" />
                        <circle cx="4" cy="16" r="4" fill="" />
                        <circle cx="4" cy="28" r="4" fill="" />
                      </svg>
                    </button>
                    <div className="relative bg-white rounded-2xl p-3 w-full grid grid-cols-1 xl:flex gap-4 ">
                      <div className="flex flex-col sm:flex-row justify-start xl:justify-between gap-3 w-full">
                        {/*<!-- display -->*/}
                        <div className="w-9/12 sm:w-1/3 xl:w-7/12 h-24 bg-brand-gray-100 border rounded-lg"></div>
                        {/*<!-- week -->*/}
                        <div className="w-full sm:w-1/2 xl:w-full mt-1.5">
                          <h5 className="font-inter font-medium text-sm text-brand-blue">
                            Days of week
                          </h5>
                          <div className="mt-2 week-list1 flex justify-between rounded-lg sm:h-12 gap-2 w-full">
                            <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                              Mon
                            </button>
                            <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                              tue
                            </button>
                            <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                              wed
                            </button>
                            <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                              thu
                            </button>
                            <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                              sat
                            </button>
                            <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                              sun
                            </button>
                          </div>
                        </div>
                      </div>
                      {/*<!-- group -->*/}
                      <div className="mt-1.5">
                        <h5 className="font-inter font-medium text-sm text-black">
                          Group
                        </h5>
                        <div className="mt-2 flex justify-between gap-3  ">
                          <div className="flex gap-3">
                            <div className="flex gap-3">
                              <div
                                id="attributes"
                                className="flex flex-col space-y-3"
                              >
                                <div className="attr flex gap-3 relative ">
                                  <div className="flex">
                                    {/*<!-- input -->*/}
                                    <div className="">
                                      <input  onChange={()=>{}} 
                                        className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter bg-brand-gray-100 rounded-lg text-brand-gray-200 placeholder-brand-gray-200 text-center focus:outline-none "
                                        placeholder="start"
                                        type="text"
                                      />
                                      <div
                                        id="box"
                                        className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                      >
                                        <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                          Time
                                        </h1>
                                        <div className="grid grid-cols-6 mt-2 gap-1.5">
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            00
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            01
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            02
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            03
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            04
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            05
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            06
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            07
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            08
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            09
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            10
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            11
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            12
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            13
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            14
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            15
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            16
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            17
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            18
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            19
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            20
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            21
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            22
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            23
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            24
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <button className="px-2">
                                      <svg
                                        width="5"
                                        height="8"
                                        viewBox="0 0 5 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1.30291 7.79647L4.55624 4.93447C4.84038 4.68444 5 4.34536 5 3.99181C5 3.63826 4.84038 3.29918 4.55624 3.04914L1.30291 0.187141C1.15999 0.0657024 0.968559 -0.0014935 0.769861 2.52387e-05C0.571162 0.00154397 0.381091 0.0716572 0.240585 0.195263C0.100078 0.318868 0.0203793 0.486077 0.0186527 0.660874C0.016926 0.835672 0.0933101 1.00407 0.231353 1.12981L3.48468 3.99181L0.231354 6.85381C0.158974 6.91531 0.101242 6.98887 0.0615251 7.07021C0.0218084 7.15154 0.000902967 7.23902 2.85837e-05 7.32754C-0.000845799 7.41606 0.0183282 7.50385 0.0564322 7.58578C0.0945361 7.66771 0.150807 7.74214 0.221961 7.80474C0.293115 7.86733 0.377726 7.91684 0.47086 7.95036C0.563993 7.98388 0.663784 8.00074 0.764407 7.99997C0.86503 7.99921 0.96447 7.98081 1.05693 7.94588C1.14938 7.91094 1.23301 7.86015 1.30291 7.79647Z"
                                          fill="#00274E"
                                        />
                                      </svg>
                                    </button>
                                    {/*<!-- input -->*/}
                                    <div>
                                      <input  onChange={()=>{}} 
                                        className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter text-brand-blue placeholder-brand-lightblue bg-brand-gray-100 rounded-lg text-center focus:outline-none"
                                        placeholder="end"
                                        type="text"
                                      />
                                      <div
                                        id="box"
                                        className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                      >
                                        <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                          Time
                                        </h1>
                                        <div className="grid grid-cols-6 mt-2 gap-1.5">
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            00
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            01
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            02
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            03
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            04
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            05
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            06
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            07
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            08
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            09
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            10
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            11
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            12
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            13
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            14
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            15
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            16
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            17
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            18
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            19
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            20
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            21
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            22
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            23
                                          </button>
                                          <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                            24
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button className="absolute hidden -right-[2.75rem] btn1 remove mt-3.5 rounded-lg bg-brand-green h-6 w-7">
                                    <div className="mt-0.5 w-2 h-0.5 bg-white mx-auto"></div>
                                  </button>
                                </div>
                              </div>
                              <button className="add h-12">
                                <svg
                                  width="25"
                                  height="25"
                                  viewBox="0 0 23 23"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="23"
                                    height="23"
                                    rx="10"
                                    fill="#A0C51E"
                                  />
                                  <path
                                    d="M15 11.5C15 11.7761 14.7761 12 14.5 12H13C12.4477 12 12 12.4477 12 13V14.5C12 14.7761 11.7761 15 11.5 15C11.2239 15 11 14.7761 11 14.5V13C11 12.4477 10.5523 12 10 12H8.5C8.22386 12 8 11.7761 8 11.5C8 11.2239 8.22386 11 8.5 11H10C10.5523 11 11 10.5523 11 10V8.5C11 8.22386 11.2239 8 11.5 8C11.7761 8 12 8.22386 12 8.5V10C12 10.5523 12.4477 11 13 11H14.5C14.7761 11 15 11.2239 15 11.5Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="">
                            <div className="relative">
                              <label
                                id="date-label1"
                                className="mt-3 ml-3 z-30 absolute top-0 text-base font-normal font-inter text-brand-gray-200"
                              >
                                pick a date
                              </label>
                              <input  onChange={()=>{}} 
                                className="relative z-50 pl-3  h-12 w-full xl:w-40 text-base font-normal font-inter text-brand-gray-200 bg-brand-gray-100 rounded-lg text-left focus:outline-none"
                                placeholder="pick a date"
                                type="text"
                                id="datepicker1"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        {/*<!-- delete btn -->*/}
                        <button className="delete-btn absolute top-0 right-4 xl:right-0 xl:relative mt-12 xl:mt-9">
                          <svg
                            className="mx-auto"
                            width="48"
                            height="46"
                            viewBox="0 0 48 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="46"
                              height="44"
                              rx="10"
                              fill="white"
                              stroke="#C5281E"
                            />
                            <path
                              d="M28.0199 17.8868L27.03 15H21.3265L20.3382 17.8868H17V19.4906H31.3846V17.8868H28.0199ZM22.0627 17.8868L22.5021 16.6038H25.8554L26.2951 17.8868H22.0627ZM28.7692 30.3962H19.6154V20.7736H17.9808V32H30.4038V20.7736H28.7692V30.3962ZM21.5769 20.7736V28.7925H23.2115V20.7736H21.5769ZM25.1731 20.7736V28.7925H26.8077V20.7736H25.1731Z"
                              fill="#C5281E"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*<!-- 2 -->*/}
                  <div className="flex gap-7">
                    <button className="">
                      <svg
                        className="hover-svg"
                        width="8"
                        height="32"
                        viewBox="0 0 8 32"
                        fill="#D9D9D9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="" />
                        <circle cx="4" cy="16" r="4" fill="" />
                        <circle cx="4" cy="28" r="4" fill="" />
                      </svg>
                    </button>
                    <div className="relative bg-white rounded-2xl p-3 w-full">
                      <div className=" grid grid-cols-1 xl:flex gap-4 ">
                        <div className="flex flex-col sm:flex-row justify-start xl:justify-between gap-3 w-full">
                          {/*<!-- display -->*/}
                          <div className="w-9/12 sm:w-1/3 xl:w-7/12 h-24 bg-brand-gray-100 border rounded-lg"></div>
                          {/*<!-- week -->*/}
                          <div className="w-full sm:w-1/2 xl:w-full mt-1.5">
                            <h5 className="font-inter font-medium text-sm text-brand-blue">
                              Days of week
                            </h5>
                            <div className="mt-2 week-list2 flex justify-between rounded-lg sm:h-12 gap-2 w-full">
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                Mon
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                tue
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                wed
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                thu
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                sat
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                sun
                              </button>
                            </div>
                          </div>
                        </div>
                        {/*<!-- group -->*/}
                        <div className="mt-1.5">
                          <h5 className="font-inter font-medium text-sm text-black">
                            Group
                          </h5>
                          <div className="mt-2 flex justify-between gap-3  ">
                            <div className="flex gap-3">
                              <div className="flex gap-3">
                                <div
                                  id="attributes2"
                                  className="flex flex-col space-y-3"
                                >
                                  <div className="attr2 flex gap-3 relative ">
                                    <div className="flex">
                                      {/*<!-- input -->*/}
                                      <div className="">
                                        <input  onChange={()=>{}} 
                                          className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter bg-brand-gray-100 rounded-lg text-brand-gray-200 placeholder-brand-gray-200 text-center focus:outline-none "
                                          placeholder="start"
                                          type="text"
                                        />
                                        <div
                                          id="box"
                                          className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                        >
                                          <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                            Time
                                          </h1>
                                          <div className="grid grid-cols-6 mt-2 gap-1.5">
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              00
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              01
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              02
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              03
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              04
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              05
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              06
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              07
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              08
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              09
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              10
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              11
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              12
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              13
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              14
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              15
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              16
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              17
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              18
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              19
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              20
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              21
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              22
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              23
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              24
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <button className="px-2">
                                        <svg
                                          width="5"
                                          height="8"
                                          viewBox="0 0 5 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.30291 7.79647L4.55624 4.93447C4.84038 4.68444 5 4.34536 5 3.99181C5 3.63826 4.84038 3.29918 4.55624 3.04914L1.30291 0.187141C1.15999 0.0657024 0.968559 -0.0014935 0.769861 2.52387e-05C0.571162 0.00154397 0.381091 0.0716572 0.240585 0.195263C0.100078 0.318868 0.0203793 0.486077 0.0186527 0.660874C0.016926 0.835672 0.0933101 1.00407 0.231353 1.12981L3.48468 3.99181L0.231354 6.85381C0.158974 6.91531 0.101242 6.98887 0.0615251 7.07021C0.0218084 7.15154 0.000902967 7.23902 2.85837e-05 7.32754C-0.000845799 7.41606 0.0183282 7.50385 0.0564322 7.58578C0.0945361 7.66771 0.150807 7.74214 0.221961 7.80474C0.293115 7.86733 0.377726 7.91684 0.47086 7.95036C0.563993 7.98388 0.663784 8.00074 0.764407 7.99997C0.86503 7.99921 0.96447 7.98081 1.05693 7.94588C1.14938 7.91094 1.23301 7.86015 1.30291 7.79647Z"
                                            fill="#00274E"
                                          />
                                        </svg>
                                      </button>
                                      {/*<!-- input -->*/}
                                      <div className="">
                                        <input  onChange={()=>{}} 
                                          className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter text-brand-blue placeholder-brand-lightblue bg-brand-gray-100 rounded-lg text-center focus:outline-none"
                                          placeholder="end"
                                          type="text"
                                        />
                                        <div
                                          id="box"
                                          className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                        >
                                          <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                            Time
                                          </h1>
                                          <div className="grid grid-cols-6 mt-2 gap-1.5">
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              00
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              01
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              02
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              03
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              04
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              05
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              06
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              07
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              08
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              09
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              10
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              11
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              12
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              13
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              14
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              15
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              16
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              17
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              18
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              19
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              20
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              21
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              22
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              23
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              24
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button className="absolute hidden -right-[2.75rem] btn2 remove2 mt-3.5 rounded-lg bg-brand-green h-6 w-7">
                                      <div className="mt-0.5 w-2 h-0.5 bg-white mx-auto"></div>
                                    </button>
                                  </div>
                                </div>
                                <button className="add2 h-12">
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="23"
                                      height="23"
                                      rx="10"
                                      fill="#A0C51E"
                                    />
                                    <path
                                      d="M15 11.5C15 11.7761 14.7761 12 14.5 12H13C12.4477 12 12 12.4477 12 13V14.5C12 14.7761 11.7761 15 11.5 15C11.2239 15 11 14.7761 11 14.5V13C11 12.4477 10.5523 12 10 12H8.5C8.22386 12 8 11.7761 8 11.5C8 11.2239 8.22386 11 8.5 11H10C10.5523 11 11 10.5523 11 10V8.5C11 8.22386 11.2239 8 11.5 8C11.7761 8 12 8.22386 12 8.5V10C12 10.5523 12.4477 11 13 11H14.5C14.7761 11 15 11.2239 15 11.5Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="">
                              <div className="relative">
                                <label
                                  id="date-label2"
                                  className="mt-3 ml-3 z-30 absolute top-0 text-base font-normal font-inter text-brand-gray-200"
                                >
                                  pick a date
                                </label>
                                <input  onChange={()=>{}} 
                                  className="relative z-50 pl-3  h-12 w-full xl:w-40 text-base font-normal font-inter text-brand-gray-200 bg-brand-gray-100 rounded-lg text-left focus:outline-none"
                                  placeholder="pick a date"
                                  type="text"
                                  id="datepicker2"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {/*<!-- delete btn -->*/}
                          <button className="delete-btn absolute top-0 right-4 xl:right-0 xl:relative mt-12 xl:mt-9">
                            <svg
                              className="mx-auto"
                              width="48"
                              height="46"
                              viewBox="0 0 48 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1"
                                y="1"
                                width="46"
                                height="44"
                                rx="10"
                                fill="white"
                                stroke="#C5281E"
                              />
                              <path
                                d="M28.0199 17.8868L27.03 15H21.3265L20.3382 17.8868H17V19.4906H31.3846V17.8868H28.0199ZM22.0627 17.8868L22.5021 16.6038H25.8554L26.2951 17.8868H22.0627ZM28.7692 30.3962H19.6154V20.7736H17.9808V32H30.4038V20.7736H28.7692V30.3962ZM21.5769 20.7736V28.7925H23.2115V20.7736H21.5769ZM25.1731 20.7736V28.7925H26.8077V20.7736H25.1731Z"
                                fill="#C5281E"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<!-- 3 -->*/}
                  <div className="flex gap-7">
                    <button className="">
                      <svg
                        className="hover-svg"
                        width="8"
                        height="32"
                        viewBox="0 0 8 32"
                        fill="#D9D9D9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="" />
                        <circle cx="4" cy="16" r="4" fill="" />
                        <circle cx="4" cy="28" r="4" fill="" />
                      </svg>
                    </button>
                    <div className="relative bg-white rounded-2xl p-3 w-full">
                      <div className="grid grid-cols-1 xl:flex gap-4 ">
                        {/*<!-- button -->*/}
                        <div className="flex flex-col sm:flex-row justify-start xl:justify-between gap-3 w-full">
                          {/*<!-- display -->*/}
                          <div className="w-9/12 sm:w-1/3 xl:w-7/12 h-24 bg-brand-gray-100 border rounded-lg"></div>
                          {/*<!-- week -->*/}
                          <div className="w-full sm:w-1/2 xl:w-full mt-1.5">
                            <h5 className="font-inter font-medium text-sm text-brand-blue">
                              Days of week
                            </h5>
                            <div className="mt-2 week-list3 flex justify-between rounded-lg sm:h-12 gap-2 w-full">
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                Mon
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                tue
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                wed
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                thu
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                sat
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                sun
                              </button>
                            </div>
                          </div>
                        </div>
                        {/*<!-- group -->*/}
                        <div className="mt-1.5">
                          <h5 className="font-inter font-medium text-sm text-brand-blue">
                            Group
                          </h5>
                          <div className="mt-2 flex justify-between gap-3  ">
                            <div className="flex gap-3">
                              <div className="flex gap-3">
                                <div
                                  id="attributes3"
                                  className="flex flex-col space-y-3"
                                >
                                  <div className="attr3 flex gap-3 relative ">
                                    <div className="flex">
                                      {/*<!-- input -->*/}
                                      <div className="">
                                        <input  onChange={()=>{}} 
                                          className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter bg-brand-gray-100 rounded-lg text-brand-gray-200 placeholder-brand-gray-200 text-center focus:outline-none "
                                          placeholder="start"
                                          type="text"
                                        />
                                        <div
                                          id="box"
                                          className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                        >
                                          <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                            Time
                                          </h1>
                                          <div className="grid grid-cols-6 mt-2 gap-1.5">
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              00
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              01
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              02
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              03
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              04
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              05
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              06
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              07
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              08
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              09
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              10
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              11
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              12
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              13
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              14
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              15
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              16
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              17
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              18
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              19
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              20
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              21
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              22
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              23
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              24
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <button className="px-2">
                                        <svg
                                          width="5"
                                          height="8"
                                          viewBox="0 0 5 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.30291 7.79647L4.55624 4.93447C4.84038 4.68444 5 4.34536 5 3.99181C5 3.63826 4.84038 3.29918 4.55624 3.04914L1.30291 0.187141C1.15999 0.0657024 0.968559 -0.0014935 0.769861 2.52387e-05C0.571162 0.00154397 0.381091 0.0716572 0.240585 0.195263C0.100078 0.318868 0.0203793 0.486077 0.0186527 0.660874C0.016926 0.835672 0.0933101 1.00407 0.231353 1.12981L3.48468 3.99181L0.231354 6.85381C0.158974 6.91531 0.101242 6.98887 0.0615251 7.07021C0.0218084 7.15154 0.000902967 7.23902 2.85837e-05 7.32754C-0.000845799 7.41606 0.0183282 7.50385 0.0564322 7.58578C0.0945361 7.66771 0.150807 7.74214 0.221961 7.80474C0.293115 7.86733 0.377726 7.91684 0.47086 7.95036C0.563993 7.98388 0.663784 8.00074 0.764407 7.99997C0.86503 7.99921 0.96447 7.98081 1.05693 7.94588C1.14938 7.91094 1.23301 7.86015 1.30291 7.79647Z"
                                            fill="#00274E"
                                          />
                                        </svg>
                                      </button>
                                      {/*<!-- input -->*/}
                                      <div className="">
                                        <input  onChange={()=>{}} 
                                          className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter text-brand-blue placeholder-brand-lightblue bg-brand-gray-100 rounded-lg text-center focus:outline-none"
                                          placeholder="end"
                                          type="text"
                                        />
                                        <div
                                          id="box"
                                          className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                        >
                                          <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                            Time
                                          </h1>
                                          <div className="grid grid-cols-6 mt-2 gap-1.5">
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              00
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              01
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              02
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              03
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              04
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              05
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              06
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              07
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              08
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              09
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              10
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              11
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              12
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              13
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              14
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              15
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              16
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              17
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              18
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              19
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              20
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              21
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              22
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              23
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              24
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button className="absolute hidden -right-[2.75rem] btn3 remove3 mt-3.5 rounded-lg bg-brand-green h-6 w-7">
                                      <div className="mt-0.5 w-2 h-0.5 bg-white mx-auto"></div>
                                    </button>
                                  </div>
                                </div>
                                <button className="add3 h-12">
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="23"
                                      height="23"
                                      rx="10"
                                      fill="#A0C51E"
                                    />
                                    <path
                                      d="M15 11.5C15 11.7761 14.7761 12 14.5 12H13C12.4477 12 12 12.4477 12 13V14.5C12 14.7761 11.7761 15 11.5 15C11.2239 15 11 14.7761 11 14.5V13C11 12.4477 10.5523 12 10 12H8.5C8.22386 12 8 11.7761 8 11.5C8 11.2239 8.22386 11 8.5 11H10C10.5523 11 11 10.5523 11 10V8.5C11 8.22386 11.2239 8 11.5 8C11.7761 8 12 8.22386 12 8.5V10C12 10.5523 12.4477 11 13 11H14.5C14.7761 11 15 11.2239 15 11.5Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="">
                              <div className="relative">
                                <label
                                  id="date-label3"
                                  className="mt-3 ml-3 z-30 absolute top-0 text-base font-normal font-inter text-brand-gray-200"
                                >
                                  pick a date
                                </label>
                                <input  onChange={()=>{}} 
                                  className="relative z-50 pl-3  h-12 w-full xl:w-40 text-base font-normal font-inter text-brand-gray-200 bg-brand-gray-100 rounded-lg text-left focus:outline-none"
                                  placeholder="pick a date"
                                  type="text"
                                  id="datepicker3"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {/*<!-- delete btn -->*/}
                          <button className="delete-btn absolute top-0 right-4 xl:right-0 xl:relative mt-12 xl:mt-9">
                            <svg
                              className="mx-auto"
                              width="48"
                              height="46"
                              viewBox="0 0 48 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1"
                                y="1"
                                width="46"
                                height="44"
                                rx="10"
                                fill="white"
                                stroke="#C5281E"
                              />
                              <path
                                d="M28.0199 17.8868L27.03 15H21.3265L20.3382 17.8868H17V19.4906H31.3846V17.8868H28.0199ZM22.0627 17.8868L22.5021 16.6038H25.8554L26.2951 17.8868H22.0627ZM28.7692 30.3962H19.6154V20.7736H17.9808V32H30.4038V20.7736H28.7692V30.3962ZM21.5769 20.7736V28.7925H23.2115V20.7736H21.5769ZM25.1731 20.7736V28.7925H26.8077V20.7736H25.1731Z"
                                fill="#C5281E"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<!-- 4 -->*/}
                  <div className="flex gap-7">
                    <button className="">
                      <svg
                        className="hover-svg"
                        width="8"
                        height="32"
                        viewBox="0 0 8 32"
                        fill="#D9D9D9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="" />
                        <circle cx="4" cy="16" r="4" fill="" />
                        <circle cx="4" cy="28" r="4" fill="" />
                      </svg>
                    </button>
                    <div className="relative bg-white rounded-2xl p-3 w-full">
                      <div className="grid grid-cols-1 xl:flex gap-4 ">
                        <div className="flex flex-col sm:flex-row justify-start xl:justify-between gap-3 w-full">
                          {/*<!-- display -->*/}
                          <div className="w-9/12 sm:w-1/3 xl:w-7/12 h-24 bg-brand-gray-100 border rounded-lg"></div>
                          {/*<!-- week -->*/}
                          <div className="w-full sm:w-1/2 xl:w-full mt-1.5">
                            <h5 className="font-inter font-medium text-sm text-brand-blue">
                              Days of week
                            </h5>
                            <div className="mt-2 week-list4 flex justify-between rounded-lg sm:h-12 gap-2 w-full">
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                Mon
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                tue
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                wed
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                thu
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                sat
                              </button>
                              <button className="bg-brand-gray-100 w-16 sm:w-full px-2 py-3 lg:p-3 rounded-lg font-inter font-bold text-sm text-brand-blue hover:bg-brand-green hover:text-white transition-all delay-75 ease-in-out">
                                sun
                              </button>
                            </div>
                          </div>
                        </div>
                        {/*<!-- group -->*/}
                        <div className="mt-1.5">
                          <h5 className="font-inter font-medium text-sm text-black">
                            Group
                          </h5>
                          <div className="mt-2 flex justify-between gap-3  ">
                            <div className="flex gap-3">
                              <div className="flex gap-3">
                                <div
                                  id="attributes4"
                                  className="flex flex-col space-y-3"
                                >
                                  <div className="attr4 flex gap-3 relative ">
                                    <div className="flex">
                                      {/*<!-- input -->*/}
                                      <div className="">
                                        <input  onChange={()=>{}} 
                                          className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter bg-brand-gray-100 rounded-lg text-brand-gray-200 placeholder-brand-gray-200 text-center focus:outline-none "
                                          placeholder="start"
                                          type="text"
                                        />
                                        <div
                                          id="box"
                                          className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                        >
                                          <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                            Time
                                          </h1>
                                          <div className="grid grid-cols-6 mt-2 gap-1.5">
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              00
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              01
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              02
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              03
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              04
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              05
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              06
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              07
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              08
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              09
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              10
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              11
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              12
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              13
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              14
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              15
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              16
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              17
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              18
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              19
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              20
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              21
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              22
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              23
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              24
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <button className="px-2">
                                        <svg
                                          width="5"
                                          height="8"
                                          viewBox="0 0 5 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.30291 7.79647L4.55624 4.93447C4.84038 4.68444 5 4.34536 5 3.99181C5 3.63826 4.84038 3.29918 4.55624 3.04914L1.30291 0.187141C1.15999 0.0657024 0.968559 -0.0014935 0.769861 2.52387e-05C0.571162 0.00154397 0.381091 0.0716572 0.240585 0.195263C0.100078 0.318868 0.0203793 0.486077 0.0186527 0.660874C0.016926 0.835672 0.0933101 1.00407 0.231353 1.12981L3.48468 3.99181L0.231354 6.85381C0.158974 6.91531 0.101242 6.98887 0.0615251 7.07021C0.0218084 7.15154 0.000902967 7.23902 2.85837e-05 7.32754C-0.000845799 7.41606 0.0183282 7.50385 0.0564322 7.58578C0.0945361 7.66771 0.150807 7.74214 0.221961 7.80474C0.293115 7.86733 0.377726 7.91684 0.47086 7.95036C0.563993 7.98388 0.663784 8.00074 0.764407 7.99997C0.86503 7.99921 0.96447 7.98081 1.05693 7.94588C1.14938 7.91094 1.23301 7.86015 1.30291 7.79647Z"
                                            fill="#00274E"
                                          />
                                        </svg>
                                      </button>
                                      {/*<!-- input -->*/}
                                      <div className="">
                                        <input  onChange={()=>{}} 
                                          className="input-check h-12 w-full xl:w-16 text-base font-medium font-inter text-brand-blue placeholder-brand-lightblue bg-brand-gray-100 rounded-lg text-center focus:outline-none"
                                          placeholder="end"
                                          type="text"
                                        />
                                        <div
                                          id="box"
                                          className="hidden mt-2 -ml-20 absolute w-72 bg-white border-2 p-3 rounded-xl z-40"
                                        >
                                          <h1 className="text-base font-semibold font-inter text-brand-blue-600">
                                            Time
                                          </h1>
                                          <div className="grid grid-cols-6 mt-2 gap-1.5">
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              00
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              01
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              02
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              03
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              04
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              05
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              06
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              07
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              08
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              09
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              10
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              11
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              12
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              13
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              14
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              15
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              16
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              17
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              18
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              19
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              20
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              21
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              22
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              23
                                            </button>
                                            <button className="py-1 text-xs font-inter font-medium text-brand-blue rounded hover:bg-brand-blue hover:text-white transition-all delay-50 ease-in-out">
                                              24
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button className="absolute hidden -right-[2.75rem] btn4 remove4 mt-3.5 rounded-lg bg-brand-green h-6 w-7">
                                      <div className="mt-0.5 w-2 h-0.5 bg-white mx-auto"></div>
                                    </button>
                                  </div>
                                </div>
                                <button className="add4 h-12">
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="23"
                                      height="23"
                                      rx="10"
                                      fill="#A0C51E"
                                    />
                                    <path
                                      d="M15 11.5C15 11.7761 14.7761 12 14.5 12H13C12.4477 12 12 12.4477 12 13V14.5C12 14.7761 11.7761 15 11.5 15C11.2239 15 11 14.7761 11 14.5V13C11 12.4477 10.5523 12 10 12H8.5C8.22386 12 8 11.7761 8 11.5C8 11.2239 8.22386 11 8.5 11H10C10.5523 11 11 10.5523 11 10V8.5C11 8.22386 11.2239 8 11.5 8C11.7761 8 12 8.22386 12 8.5V10C12 10.5523 12.4477 11 13 11H14.5C14.7761 11 15 11.2239 15 11.5Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="">
                              <div className="relative">
                                <label
                                  id="date-label4"
                                  className="mt-3 ml-3 z-30 absolute top-0 text-base font-normal font-inter text-brand-gray-200"
                                >
                                  Pick a Date{" "}
                                </label>
                                <input  onChange={()=>{}} 
                                  className="relative z-50 pl-3  h-12 w-full xl:w-40 text-base font-normal font-inter text-brand-gray-200 bg-brand-gray-100 rounded-lg text-left focus:outline-none"
                                  placeholder="pick a date"
                                  type="text"
                                  id="datepicker4"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {/*<!-- delete btn -->*/}
                          <button className="delete-btn absolute top-0 right-4 xl:right-0 xl:relative mt-12 xl:mt-9">
                            <svg
                              className="mx-auto"
                              width="48"
                              height="46"
                              viewBox="0 0 48 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1"
                                y="1"
                                width="46"
                                height="44"
                                rx="10"
                                fill="white"
                                stroke="#C5281E"
                              />
                              <path
                                d="M28.0199 17.8868L27.03 15H21.3265L20.3382 17.8868H17V19.4906H31.3846V17.8868H28.0199ZM22.0627 17.8868L22.5021 16.6038H25.8554L26.2951 17.8868H22.0627ZM28.7692 30.3962H19.6154V20.7736H17.9808V32H30.4038V20.7736H28.7692V30.3962ZM21.5769 20.7736V28.7925H23.2115V20.7736H21.5769ZM25.1731 20.7736V28.7925H26.8077V20.7736H25.1731Z"
                                fill="#C5281E"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative col-span-1 lg:col-span-4 xl:col-span-3 mt-20 space-y-6">
                <div className="relative lg:-top-44 w-full bg-white py-6 px-8 rounded-3xl border space-y-5">
                  <div className="hidden lg:block border-b-4 border-dashed border-brand-gray-100 pb-4">
                    <div className="py-1.5 w-4/12 px-2 bg-brand-green rounded-lg">
                      <h2 className="font-inter text-sm font-medium text-white text-center">
                        Emission
                      </h2>
                    </div>
                    <div className="relative" x-data="{ open: false }">
                      <button className="flex flex-row justify-between items-center w-full py-2 mt-2 font-inter text-2xl font-bold text-brand-blue">
                        <span>Fikiri Studio</span>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          className="inline w-6 h-6 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="absolute bg-white left-0 w-full border shadow-lg rounded-lg">
                        <div className="">
                          <a
                            className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                            href="#"
                          >
                            Siyira Studio
                          </a>
                          <a
                            className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                            href="#"
                          >
                            Dikiri Studio
                          </a>
                          <a
                            className="w-full block px-4 py-3 text-sm font-medium font-inter text-brand-blue hover:bg-brand-gray transition-all delay-100 ease-in-out rounded-lg"
                            href="#"
                          >
                            QWssi Studio
                          </a>
                        </div>
                      </div>
                    </div>
                    <button className="mt-3 w-full flex justify-between bg-brand-gray-100 py-3 px-5 rounded-xl font-inter text-brand-green font-medium">
                      Upload files
                      <svg
                        className="mt-1.5"
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.14286 10.2143H7.85714V5.5H11L5.5 0L0 5.5H3.14286V10.2143ZM0 11.7857H11V13.3571H0V11.7857Z"
                          fill="#A0C51E"
                        />
                      </svg>
                    </button>
                  </div>
                  {/*<!--Configuration  -->*/}
                  <div className="space-y-5 border-b-4 border-dashed border-brand-gray-100 pb-7">
                    {/*<!-- emission -->*/}
                    <div className="">
                      {/*<!-- title -->*/}
                      <h4 className="ml-2 text-brand-blue font-inter font-bold text-base">
                        Configuration
                      </h4>
                      <div className="flex justify-between gap-1">
                        {/*<!-- input -->*/}
                        <div className="mt-2 relative w-full">
                          <p className="ml-2 mt-2.5 tex-sm font-inter font-medium text-brand-blue">
                            Emussion Key :
                          </p>
                          <input  onChange={()=>{}} 
                            className="mt-2 pl-5 bg-brand-gray-100 rounded-lg w-full h-12 text-black font-medium font-inter text-sm placeholder-black focus:outline-none"
                            type="text"
                            placeholder="6t584f585"
                            name=""
                            id=""
                          />
                        </div>
                        {/*<!-- toggle -->*/}
                        <div className="mt-2">
                          {/*<!-- label -->*/}
                          <div className="ml-2 mt-2.5 tex-sm font-inter font-medium text-brand-blue">
                            Audio
                          </div>
                          <label className="mt-2 flex items-center cursor-pointer bg-brand-gray-100 rounded-lg h-12 px-2">
                            {/*<!-- toggle -->*/}
                            <div className="relative">
                              {/*<!-- input -->*/}
                              <input  onChange={()=>{}} 
                                type="checkbox"
                                id="toggleA"
                                className="sr-only"
                              />
                              {/*<!-- line -->*/}
                              <div className="block bg-white w-12 h-5 rounded-full"></div>
                              {/*<!-- dot -->*/}
                              <div className="dot absolute left-0 -top-0.5 bg-brand-blue w-6 h-6 rounded-full transition"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*<!-- configuration -->*/}
                    <div className="mt-2 relative w-full">
                      <div className="flex justify-between">
                        <p className="ml-2 mt-2.5 tex-sm font-inter font-bold text-brand-blue">
                          Callers
                        </p>
                        <button className="mt-2">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.42857 5.77143C8.85462 5.77143 9.2 5.42605 9.2 5C9.2 4.57395 8.85462 4.22857 8.42857 4.22857H6.57143C6.1296 4.22857 5.77143 3.8704 5.77143 3.42857V1.57143C5.77143 1.14538 5.42605 0.8 5 0.8C4.57395 0.8 4.22857 1.14538 4.22857 1.57143V3.42857C4.22857 3.8704 3.8704 4.22857 3.42857 4.22857H1.57143C1.14538 4.22857 0.8 4.57395 0.8 5C0.8 5.42605 1.14538 5.77143 1.57143 5.77143H3.42857C3.8704 5.77143 4.22857 6.1296 4.22857 6.57143V8.42857C4.22857 8.85462 4.57395 9.2 5 9.2C5.42605 9.2 5.77143 8.85462 5.77143 8.42857V6.57143C5.77143 6.1296 6.1296 5.77143 6.57143 5.77143H8.42857Z"
                              fill="#00274E"
                              stroke="black"
                              strokeWidth="0.4"
                            />
                          </svg>
                        </button>
                      </div>
                      <h1 className="ml-3 bg-transparent rounded-lg text-black font-medium font-inter text-sm placeholder-black focus:outline-none">
                        Panaderia
                      </h1>
                      <div className="flex justify-between relative bg-brand-gray-100 rounded-lg w-full py-2 flex gap-5 px-4 mt-2.5">
                        <select className="py-1.5 px-1 bg-white w-full rounded-3xl flex justify-between gap-2 font-bold font-inter text-xs text-brand-blue focus:outline-none">
                          <option>Select</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                          <option>29</option>
                          <option>30</option>
                          <option>32</option>
                          <option>32</option>
                          <option>33</option>
                          <option>34</option>
                          <option>35</option>
                          <option>36</option>
                          <option>37</option>
                          <option>38</option>
                          <option>39</option>
                          <option>40</option>
                          <option>41</option>
                          <option>42</option>
                          <option>43</option>
                          <option>44</option>
                          <option>45</option>
                          <option>46</option>
                          <option>47</option>
                          <option>48</option>
                          <option>49</option>
                          <option>50</option>
                          <option>52</option>
                          <option>53</option>
                          <option>54</option>
                          <option>55</option>
                          <option>56</option>
                          <option>57</option>
                          <option>58</option>
                          <option>59</option>
                          <option>60</option>
                          <option>61</option>
                          <option>62</option>
                          <option>63</option>
                          <option>64</option>
                          <option>65</option>
                          <option>66</option>
                          <option>67</option>
                          <option>68</option>
                          <option>69</option>
                          <option>70</option>
                          <option>71</option>
                          <option>72</option>
                          <option>73</option>
                          <option>74</option>
                          <option>75</option>
                          <option>76</option>
                          <option>77</option>
                          <option>78</option>
                          <option>79</option>
                          <option>80</option>
                          <option>81</option>
                          <option>82</option>
                          <option>83</option>
                          <option>84</option>
                          <option>85</option>
                          <option>86</option>
                          <option>87</option>
                          <option>88</option>
                          <option>89</option>
                          <option>90</option>
                          <option>91</option>
                          <option>92</option>
                          <option>93</option>
                          <option>94</option>
                          <option>95</option>
                          <option>96</option>
                          <option>97</option>
                          <option>98</option>
                          <option>99</option>
                          <option>100</option>
                        </select>
                        <select className="py-1.5 px-1 bg-white w-full rounded-3xl flex justify-between gap-2 font-bold font-inter text-xs text-brand-blue focus:outline-none">
                          <option>Select</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                          <option>29</option>
                          <option>30</option>
                          <option>32</option>
                          <option>32</option>
                          <option>33</option>
                          <option>34</option>
                          <option>35</option>
                          <option>36</option>
                          <option>37</option>
                          <option>38</option>
                          <option>39</option>
                          <option>40</option>
                          <option>41</option>
                          <option>42</option>
                          <option>43</option>
                          <option>44</option>
                          <option>45</option>
                          <option>46</option>
                          <option>47</option>
                          <option>48</option>
                          <option>49</option>
                          <option>50</option>
                          <option>52</option>
                          <option>53</option>
                          <option>54</option>
                          <option>55</option>
                          <option>56</option>
                          <option>57</option>
                          <option>58</option>
                          <option>59</option>
                          <option>60</option>
                          <option>61</option>
                          <option>62</option>
                          <option>63</option>
                          <option>64</option>
                          <option>65</option>
                          <option>66</option>
                          <option>67</option>
                          <option>68</option>
                          <option>69</option>
                          <option>70</option>
                          <option>71</option>
                          <option>72</option>
                          <option>73</option>
                          <option>74</option>
                          <option>75</option>
                          <option>76</option>
                          <option>77</option>
                          <option>78</option>
                          <option>79</option>
                          <option>80</option>
                          <option>81</option>
                          <option>82</option>
                          <option>83</option>
                          <option>84</option>
                          <option>85</option>
                          <option>86</option>
                          <option>87</option>
                          <option>88</option>
                          <option>89</option>
                          <option>90</option>
                          <option>91</option>
                          <option>92</option>
                          <option>93</option>
                          <option>94</option>
                          <option>95</option>
                          <option>96</option>
                          <option>97</option>
                          <option>98</option>
                          <option>99</option>
                          <option>100</option>
                        </select>
                        <button className="">
                          <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="23"
                              height="23"
                              rx="10"
                              fill="#00274E"
                            />
                            <path
                              d="M10.9057 11.5347L8.12275 14.3177C8.0431 14.4001 7.99902 14.5106 8.00002 14.6253C8.00101 14.7399 8.047 14.8496 8.12808 14.9307C8.20916 15.0117 8.31883 15.0577 8.43349 15.0587C8.54815 15.0597 8.65861 15.0157 8.74108 14.936L11.524 12.1531L14.3069 14.936C14.3894 15.0157 14.4999 15.0597 14.6145 15.0587C14.7292 15.0577 14.8388 15.0117 14.9199 14.9307C15.001 14.8496 15.047 14.7399 15.048 14.6253C15.049 14.5106 15.0049 14.4001 14.9252 14.3177L12.1423 11.5347L14.9252 8.75183C14.967 8.71149 15.0003 8.66324 15.0232 8.60989C15.0462 8.55654 15.0582 8.49915 15.0587 8.44109C15.0592 8.38303 15.0482 8.32545 15.0262 8.2717C15.0042 8.21796 14.9717 8.16914 14.9307 8.12808C14.8896 8.08702 14.8408 8.05455 14.787 8.03256C14.7333 8.01058 14.6757 7.99951 14.6177 8.00002C14.5596 8.00052 14.5022 8.01258 14.4489 8.0355C14.3955 8.05842 14.3473 8.09173 14.3069 8.1335L11.524 10.9164L8.74108 8.1335C8.65861 8.05384 8.54815 8.00977 8.43349 8.01076C8.31883 8.01176 8.20916 8.05775 8.12808 8.13883C8.047 8.2199 8.00101 8.32958 8.00002 8.44424C7.99902 8.55889 8.0431 8.66936 8.12275 8.75183L10.9057 11.5347Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*<!-- display -->*/}
                  <div className="border-b-4 border-dashed border-brand-gray-100 pb-7">
                    {/*<!-- title -->*/}
                    <div className="flex justify-between">
                      <h4 className="text-brand-blue font-inter font-bold text-base">
                        Display
                      </h4>
                    </div>
                    {/*<!-- displaybody -->*/}
                    <div className="flex justify-between gap-2.5 mt-5">
                      <div className="w-full">
                        <button className="w-full h-full">
                          <div className="grid grid-cols-3 h-20">
                            <div className="col-span-1 border-l-2 border-b-2 border-t-2 border-brand-green w-full h-full bg-brand-gray-100 rounded-l-xl"></div>
                            <div className="col-span-2 border-2 border-brand-green w-full h-full bg-brand-gray-100 rounded-r-xl"></div>
                          </div>
                        </button>
                      </div>
                      <div className="w-full">
                        <button className="w-full h-full  rounded-3xl">
                          <div className="w-full h-20 bg-brand-gray-100 border-2 rounded-xl"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*<!--News  -->*/}
                  <div className="bg-white">
                    {/*<!-- title -->*/}
                    <h4 className="text-brand-blue font-inter font-bold text-base">
                      News
                    </h4>
                    {/*<!-- toggle -->*/}
                    <div className="space-y-5 mt-6">
                      {/*<!-- El Observador -->*/}
                      <div className="flex w-full">
                        <label className="mt-2 flex items-center cursor-pointer bg-brand-gray-100 rounded-lg h-12 px-2">
                          {/*<!-- toggle -->*/}
                          <div className="relative">
                            {/*<!-- input -->*/}
                            <input  onChange={()=>{}} 
                              type="checkbox"
                              id="toggleB"
                              className="sr-only"
                            />
                            {/*<!-- line -->*/}
                            <div className="block bg-white w-12 h-5 rounded-full"></div>
                            {/*<!-- dot -->*/}
                            <div className="dot absolute left-0 -top-0.5 bg-brand-blue w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                        {/*<!-- label -->*/}
                        <div className="mt-6 ml-3 text-brand-blue text-sm font-inter font-medium">
                          {" "}
                          El Observador{" "}
                        </div>
                      </div>
                      {/*<!-- El Pais -->*/}
                      <div className="flex w-full">
                        <label className="mt-2 flex items-center cursor-pointer bg-brand-gray-100 rounded-lg h-12 px-2">
                          {/*<!-- toggle -->*/}
                          <div className="relative">
                            {/*<!-- input -->*/}
                            <input  onChange={()=>{}} 
                              type="checkbox"
                              id="toggleC"
                              className="sr-only"
                            />
                            {/*<!-- line -->*/}
                            <div className="block bg-white w-12 h-5 rounded-full"></div>
                            {/*<!-- dot -->*/}
                            <div className="dot absolute left-0 -top-0.5 bg-brand-blue w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                        {/*<!-- label -->*/}
                        <div className="mt-6 ml-3 text-brand-blue text-sm font-inter font-medium">
                          El Pais
                        </div>
                      </div>
                      {/*<!-- Subrayado -->*/}
                      <div className="flex w-full">
                        <label className="mt-2 flex items-center cursor-pointer bg-brand-gray-100 rounded-lg h-12 px-2">
                          {/*<!-- toggle -->*/}
                          <div className="relative">
                            {/*<!-- input -->*/}
                            <input  onChange={()=>{}} 
                              type="checkbox"
                              id="toggleD"
                              className="sr-only"
                            />
                            {/*<!-- line -->*/}
                            <div className="block bg-white w-12 h-5 rounded-full"></div>
                            {/*<!-- dot -->*/}
                            <div className="dot absolute left-0 -top-0.5 bg-brand-blue w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                        {/*<!-- label -->*/}
                        <div className="mt-6 ml-3 text-brand-blue text-sm font-inter font-medium">
                          Subrayado
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<!--Sport  -->*/}
                  <div className="bg-white">
                    {/*<!-- title -->*/}
                    <h4 className="text-brand-blue font-inter font-bold text-base">
                      Sport
                    </h4>
                    {/*<!-- toggle -->*/}
                    <div className="space-y-5 mt-6">
                      {/*<!-- Liga Uruguaya de Futbol -->*/}
                      <div className="flex w-full">
                        <label className="mt-2 flex items-center cursor-pointer bg-brand-gray-100 rounded-lg h-12 px-2">
                          {/*<!-- toggle -->*/}
                          <div className="relative">
                            {/*<!-- input -->*/}
                            <input  onChange={()=>{}} 
                              type="checkbox"
                              id="toggleE"
                              className="sr-only"
                            />
                            {/*<!-- line -->*/}
                            <div className="block bg-white w-12 h-5 rounded-full"></div>
                            {/*<!-- dot -->*/}
                            <div className="dot absolute left-0 -top-0.5 bg-brand-blue w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                        {/*<!-- label -->*/}
                        <div className="mt-6 ml-3 text-brand-blue text-sm font-inter font-medium">
                          Liga Uruguaya de Futbol
                        </div>
                      </div>
                      {/*<!-- Champions League -->*/}
                      <div className="flex w-full">
                        <label className="mt-2 flex items-center cursor-pointer bg-brand-gray-100 rounded-lg h-12 px-2">
                          {/*<!-- toggle -->*/}
                          <div className="relative">
                            {/*<!-- input -->*/}
                            <input  onChange={()=>{}} 
                              type="checkbox"
                              id="toggleF"
                              className="sr-only"
                            />
                            {/*<!-- line -->*/}
                            <div className="block bg-white w-12 h-5 rounded-full"></div>
                            {/*<!-- dot -->*/}
                            <div className="dot absolute left-0 -top-0.5 bg-brand-blue w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                        {/*<!-- label -->*/}
                        <div className="mt-6 ml-3 text-brand-blue text-sm font-inter font-medium">
                          Champions League
                        </div>
                      </div>
                      {/*<!-- Liga Universitaria -->*/}
                      <div className="flex w-full">
                        <label className="mt-2 flex items-center cursor-pointer bg-brand-gray-100 rounded-lg h-12 px-2">
                          {/*<!-- toggle -->*/}
                          <div className="relative">
                            {/*<!-- input -->*/}
                            <input  onChange={()=>{}} 
                              type="checkbox"
                              id="toggleG"
                              className="sr-only"
                            />
                            {/*<!-- line -->*/}
                            <div className="block bg-white w-12 h-5 rounded-full"></div>
                            {/*<!-- dot -->*/}
                            <div className="dot absolute left-0 -top-0.5 bg-brand-blue w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                        {/*<!-- label -->*/}
                        <div className="mt-6 ml-3 text-brand-blue text-sm font-inter font-medium">
                          Liga Universitaria
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
