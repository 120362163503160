import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { IModule } from "./moduleSlice";

// Define a type for the slice state

interface IEmission {
  CreatedAt: string;
  Description: string;
  Email: string;
  Language: string;
  Logo: string;
  OrganizationID: number;
  Riddles: boolean;
  UpdatedAt: string;
  audio: boolean;
  basketball: boolean;
  callers: boolean;
  color: string;
  country: string;
  display: number;
  exchanges: boolean;
  football: boolean;
  key: string;
  max_connections: number;
  name: string;
  news: number;
  state: string;
  uuid: string;
  modules: IModule;
  a_day_like_today: boolean;
  did_you_know: boolean;
  instagram_enabled: boolean;
  total_instagram_posts_displayed: boolean;
  trivia: boolean;
  is_active: boolean;
  status: boolean;
  movie: boolean;

  primary_color: string;
  secondary_color: string;
  third_color: string;
  background_color: string;
  notification_color_text: string;
  background_card: string;
  slider_color_active: string;
  slider_color_disabled: string;
  color_logo: boolean;

  firebase: boolean;

  category: string;
  sub_category: string;
}

export interface IDeviceInfo {
  callers: any;
  ram: string;
  name?: string;
  status: boolean;
  storage: string;
  updated_at?: string;
  screenshot?: string;
  version_app: string;
  emission: string;
}
interface EmissionState {
  emissionList: IEmission[];
  emission: IEmission;
  pending: boolean;
  hasFetched: boolean;

  deviceInfoList: IDeviceInfo[];
  pendingDeviceInfo: boolean;
  hasFetchedDeviceInfo: boolean;
}

// Define the initial state using that type
const initialState: EmissionState = {
  emissionList: [],
  pending: false,
  hasFetched: false,
  emission: null!,

  deviceInfoList: [],
  pendingDeviceInfo: false,
  hasFetchedDeviceInfo: false,
};

export const emissionSlice = createSlice({
  name: "emission",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startFetch: (state) => {
      //state.emissionList = [];
      if (!state.hasFetched) state.pending = true;
    },

    stopFetch: (state, action: PayloadAction<IEmission[]>) => {
      state.emissionList = action.payload;
      state.pending = false;
      state.hasFetched = true;
    },

    selectedFetch: (state, action: PayloadAction<IEmission>) => {
      state.emission = action.payload;
      state.pending = false;
    },

    resetHasFetched: (state) => {
      state.hasFetched = false;
    },

    //DeviceInfo
    startDeviceInfoFetch: (state) => {
      state.pendingDeviceInfo = true;
    },

    stopDeviceInfoFetch: (state, action: PayloadAction<IDeviceInfo[]>) => {
      state.deviceInfoList = action.payload;
      state.pendingDeviceInfo = false;
      state.hasFetchedDeviceInfo = true;
    },

    resetHasFetchedDeviceInfo: (state) => {
      state.hasFetchedDeviceInfo = false;
    },
  },
});

export const {
  startFetch,
  stopFetch,
  resetHasFetched,
  selectedFetch,

  startDeviceInfoFetch,
  stopDeviceInfoFetch,
  resetHasFetchedDeviceInfo,
} = emissionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const emissionList = (state: RootState) =>
  state.emissionSlice.emissionList;
export const emissionPending = (state: RootState) =>
  state.emissionSlice.pending;
export const emissionHasFetched = (state: RootState) =>
  state.emissionSlice.hasFetched;
export const emissionOne = (state: RootState) => state.emissionSlice.emission;

export const emissionListDeviceInfo = (state: RootState) =>
  state.emissionSlice.deviceInfoList;
export const emissionPendingDeviceInfo = (state: RootState) =>
  state.emissionSlice.pendingDeviceInfo;
export const emissionHasFetchedDeviceInfo = (state: RootState) =>
  state.emissionSlice.hasFetchedDeviceInfo;

export default emissionSlice.reducer;
