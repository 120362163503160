import { useForm } from "react-hook-form";
import { LeftPanel } from "../../components/auth/LeftPanel";
import { EmailFormInput } from "../../components/form/EmailFormInput";
import { PasswordFormInput } from "../../components/form/PasswordFormInput";
import useAuth from "../../hooks/useAuth";
import { getRule } from "../../utils/formRules";
import { BackgroundBlur } from "../common/BackgroundBlur";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { PATH_PAGE } from "../../routes/paths";

const Login = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate(PATH_PAGE.root);
  }, [isAuthenticated]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { pending, login } = useAuth();
  const onSubmit = (data: any) => {
    console.log(data);
    if (typeof login === "function")
      login(data.email, data.password, () => {
        navigate("/");
      });
  };

  return (
    <>
      <BackgroundBlur />

      <section className="">
        <div className="bg-white absolute top-0 left-0 h-full w-full z-50 opacity-0"></div>
        <div
          className="absolute left-0 right-0 mx-auto top-9  w-10/12 modal-wdith h-[90vh] border bg-brand-gray rounded-3xl shadow z-50 "
          style={{ zIndex: "1000000 !important" }}
        >
          <div className="grid grid-cols-1 lg:grid-cols-12 h-full">
            <LeftPanel />
            <div className="relative col-span-1 lg:col-span-7 xl:col-span-7 w-full ">
              <div className="vertical-center w-full">
                <div  className="w-11/12 md:w-8/12 xl:w-1/2 width-login-form left-0 right-0 mx-auto bg-white rounded-3xl py-10 px-8">
                  <h1 className="text-2xl font-inter font-bold text-brand-blue">
                    Sign in to your account
                  </h1>
                  <p className="mt-3 text-brand-gray-200 font-medium font-inter text-sm">
                   
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <EmailFormInput
                      disabled={pending}
                      errors={errors}
                      label={"Email"}
                      formControl={{
                        ...register("email", { ...getRule("required") }),
                      }}
                    />
                    <PasswordFormInput
                      disabled={pending}
                      errors={errors}
                      label={"Password"}
                      formControl={{
                        ...register("password", { ...getRule("required") }),
                      }}
                    />

                    <button
                      disabled={pending}
                      type="submit"
                      className="mt-6 flex justify-center  w-full bg-brand-green py-2.5 text-base font-inter font-medium text-white rounded-lg"
                    >
                      Continue
                      {pending && (
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mt-1 ml-2"></div>
                      )}
                    </button>

                    <p className="mt-6 text-brand-gray-200 font-medium font-inter text-sm">
                      Forget your password?{" "}
                      <a href="/" className="font-bold text-brand-blue">
                        Reset Password
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
