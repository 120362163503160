import axios from "axios";
// config
import { API_BACK } from "../config";
import { showToast } from "./common";
import { fromStorage } from "./storage";

// ----------------------------------------------------------------------

export const axiosInstance = axios.create({
  baseURL: API_BACK + "/api/v1",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

let config = {};
export const asError = (code) => !(code >= 200 && code < 300 ) && code !== 404;

export const processActionService = (
  route,
  method,
  payload,
  isMultipart = false
) => {
  return new Promise((resolve, reject) => {
    try {
      if (isMultipart)
        config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
      else {
        config = {
          headers: {
            "content-type": "application/json",
          },
        };
      }

      if (method === "delete") {
        Object.assign(config, {
          data: payload,
        });
      }
      let func;
      if (method === "delete") func = axiosInstance[method](route, config);
      else func = axiosInstance[method](route, payload, config);
      func
        .then((response) => {          
          resolve(response.data?response.data:true);
        })
        .catch((e) => {
           
          if (asError(e.code)) showToast(e.error);
          reject(e);
        });
    } catch (error) {
      /*
      notification.error({
        message: "Se encontro un problema al procesar los datos",
        description: JSON.stringify(error),
      });
      */
    }
  });
};

export default axiosInstance;
