import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface ICallerPayload {
  caller: any;
  show: any;
  emissionUUID: string;
}

// Define a type for the slice state
interface ModalState {
  modalOrganizationForm: boolean;
  modalUploadForm: boolean;
  modalTriviaForm: boolean;
  modalEmisionForm: boolean;
  modalOrganizationSelected: boolean;
  modalConfirm: boolean;
  modalConfirmYes: boolean;
  modalConfirmYesArgs: any;
  modalCallersForm: ICallerPayload | null;

  modalReel: boolean;
}

// Define the initial state using that type
const initialState: ModalState = {
  modalOrganizationForm: false,
  modalUploadForm: false,
  modalReel: false,
  modalTriviaForm: false,
  modalEmisionForm: false,
  modalOrganizationSelected: false,
  modalConfirm: false,
  modalConfirmYes: false,
  modalConfirmYesArgs: null,
  modalCallersForm: null,
};

export const modalSlice = createSlice({
  name: "modal",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    switchModalOrganizationForm: (state, action: PayloadAction<boolean>) => {
      state.modalOrganizationForm = action.payload;
      window.scrollTo(0, 0);
    },

    switchReel: (state, action: PayloadAction<boolean>) => {
      state.modalReel = action.payload;
      window.scrollTo(0, 0);
    },

    switchModalUploadForm: (state, action: PayloadAction<boolean>) => {
      state.modalUploadForm = action.payload;
      window.scrollTo(0, 0);
    },

    switchModalCallersForm: (state, action: PayloadAction<ICallerPayload>) => {
      state.modalCallersForm = action.payload;
      window.scrollTo(0, 0);
    },

    switchModalTriviaForm: (state, action: PayloadAction<boolean>) => {
      state.modalTriviaForm = action.payload;
      window.scrollTo(0, 0);
    },

    switchModalEmisionForm: (state, action: PayloadAction<boolean>) => {
      state.modalEmisionForm = action.payload;
      window.scrollTo(0, 0);
    },
    switchModalOrganizationSelected: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalOrganizationSelected = action.payload;
      window.scrollTo(0, 0);
    },

    switchModalConfirm: (state, action: PayloadAction<any>) => {
      state.modalConfirm = action.payload.modalConfirm;
      state.modalConfirmYes = action.payload.yesOption;
      if (action.payload.args) state.modalConfirmYesArgs = action.payload.args;
      window.scrollTo(0, 0);
    },
  },
});

export const {
  switchModalOrganizationForm,
  switchModalEmisionForm,
  switchModalUploadForm,
  switchModalOrganizationSelected,
  switchModalConfirm,
  switchReel,
  switchModalTriviaForm,
  switchModalCallersForm,
} = modalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const modalOrganizationForm = (state: RootState) =>
  state.modalSlice.modalOrganizationForm;
export const modalUploadForm = (state: RootState) =>
  state.modalSlice.modalUploadForm;
export const modalTriviaForm = (state: RootState) =>
  state.modalSlice.modalTriviaForm;

export const modalCallersForm = (state: RootState) =>
  state.modalSlice.modalCallersForm;

export const modalReel = (state: RootState) => state.modalSlice.modalReel;

export const modalEmisionForm = (state: RootState) =>
  state.modalSlice.modalEmisionForm;
export default modalSlice.reducer;
export const modalOrganizationSelected = (state: RootState) =>
  state.modalSlice.modalOrganizationSelected;
export const modalConfirm = (state: RootState) => state.modalSlice.modalConfirm;
export const modalConfirmYes = (state: RootState) =>
  state.modalSlice.modalConfirmYes;
export const modalConfirmYesArgs = (state: RootState) =>
  state.modalSlice.modalConfirmYesArgs;
