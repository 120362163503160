export const LeftPanel = () => (
  <div className="relative col-span-1 lg:col-span-5 xl:col-span-5 bg-white w-full mx-auto hidden lg:block rounded-l-3xl">
    <div className="vertical-center w-full ">
      <div className="w-full left-0 right-0 mx-auto extra-padding">
        <div className="flex justify-center gap-3">
          <svg
            width="34"
            height="41"
            viewBox="0 0 34 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.6896 -0.000244141L16.4245 7.56541L19.0332 10.078L20.3404 5.53184L31.0899 16.7053L27.8061 30.5798L26.1162 29.2502L24.8417 28.2493L22.6167 26.4912L21.2115 25.3857L15.8503 21.1645L17.1042 16.7996L14.3555 14.4485L12.0054 22.284L20.3983 28.4781L21.8287 29.5369L24.1069 31.2203L25.4066 32.1783L29.0909 34.9019L33.4129 16.0274L18.6896 -0.000244141Z"
              fill="#0059B1"
            />
            <path
              d="M20.0789 36.3202L4.97654 26.7714L10.7243 8.81753L13.2144 11.0238L14.9306 12.5392L17.642 14.9322L19.1826 16.2926L24.1218 20.6679L23.0826 24.6967L25.2805 26.4931L26.9564 19.7603L20.021 13.2899L18.514 11.8791L15.8699 9.41042L14.1929 7.84743L8.62352 2.64307L0 28.3624L21.6671 40.9998L23.654 33.0299L21.3525 31.3922L20.0789 36.3202Z"
              fill="#A0C51E"
            />
          </svg>
          <svg
            className="mt-2"
            width="101"
            height="22"
            viewBox="0 0 101 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.48549 1.92432C14.3948 1.92432 18.5067 5.81871 18.5067 11.4563C18.5067 17.0659 14.3406 20.9874 8.32116 20.9874H0.396973V1.92432H8.48549ZM4.6994 17.3656H8.59379C11.7805 17.3656 14.1221 14.9688 14.1221 11.4834C14.1221 7.96993 11.6712 5.54701 8.40333 5.54701H4.6994V17.3656Z"
              fill="#0059B1"
            />
            <path
              d="M35.7145 13.6615C35.7145 18.1273 32.5558 21.1506 27.8715 21.1506C23.1611 21.1506 20.0015 18.1273 20.0015 13.6615C20.0015 9.16858 23.1601 6.19946 27.8715 6.19946C32.5558 6.19946 35.7145 9.16765 35.7145 13.6615ZM24.2227 13.7156C24.2227 16.1124 25.6932 17.7463 27.8715 17.7463C30.0227 17.7463 31.5213 16.1124 31.5213 13.7156C31.5213 11.3189 30.0236 9.68491 27.8715 9.68491C25.6932 9.68585 24.2227 11.3189 24.2227 13.7156Z"
              fill="#0059B1"
            />
            <path
              d="M54.3409 13.7436C54.3409 18.1823 51.6444 21.1505 47.5324 21.1505C45.4895 21.1505 43.8836 20.3335 42.8211 18.863V20.9871H38.627V0.780273H42.8211V8.48691C43.8556 7.01636 45.4625 6.19938 47.4503 6.19938C51.5622 6.19938 54.3409 9.22265 54.3409 13.7436ZM50.0917 13.6063C50.0917 11.2646 48.6211 9.57559 46.4699 9.57559C44.3187 9.57559 42.8211 11.2366 42.8211 13.6063C42.8211 16.0302 44.3187 17.6641 46.4699 17.6641C48.6211 17.6641 50.0917 16.0031 50.0917 13.6063Z"
              fill="#0059B1"
            />
            <path
              d="M57.2251 20.9871V0.780273H61.4192V20.9871H57.2251Z"
              fill="#0059B1"
            />
            <path
              d="M79.091 14.9416H68.5787C69.0688 16.7931 70.4852 17.8827 72.3648 17.8827C73.7531 17.8827 75.0603 17.3384 76.0677 16.331L78.274 18.5643C76.8035 20.1983 74.6523 21.1516 72.0109 21.1516C67.3266 21.1516 64.3584 18.1563 64.3584 13.7176C64.3584 9.19667 67.4358 6.20142 71.9016 6.20142C77.0491 6.19955 79.4449 9.60376 79.091 14.9416ZM75.1695 12.4636C75.1154 10.53 73.8624 9.27698 71.9287 9.27698C70.0772 9.27698 68.8242 10.53 68.4974 12.4636H75.1695Z"
              fill="#0059B1"
            />
            <path
              d="M90.0368 1.92432C95.9461 1.92432 100.058 5.81871 100.058 11.4563C100.058 17.0659 95.8919 20.9874 89.8724 20.9874H81.9482V1.92432H90.0368ZM86.2507 17.3656H90.1451C93.3317 17.3656 95.6734 14.9688 95.6734 11.4834C95.6734 7.96993 93.2225 5.54701 89.9546 5.54701H86.2507V17.3656Z"
              fill="#A0C51E"
            />
          </svg>
        </div>
        <div className="mt-10">
          <ul className="w-full w-10/12 xl:w-9/12 mx-auto space-y-6">
            <li className="flex gap-3">
              <div>
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16.5" cy="16.5" r="16.5" fill="#0059B1" />
                  <path
                    d="M23.8888 10.8823L15.3142 20.1305C15.2406 20.2098 15.1511 20.2726 15.0515 20.3148C14.9518 20.3569 14.8444 20.3775 14.7362 20.3752C14.6281 20.3728 14.5217 20.3476 14.424 20.3011C14.3263 20.2546 14.2395 20.188 14.1694 20.1056L10.9544 16.3219C10.8228 16.1671 10.6352 16.0709 10.4327 16.0544C10.2302 16.038 10.0294 16.1026 9.87458 16.2342C9.71976 16.3658 9.62353 16.5534 9.60708 16.7559C9.59063 16.9584 9.6553 17.1592 9.78685 17.314L13.0019 21.0966C13.2121 21.344 13.4724 21.5441 13.7656 21.6836C14.0588 21.8231 14.3782 21.8989 14.7028 21.906C15.0275 21.913 15.3499 21.8512 15.6489 21.7246C15.9478 21.5979 16.2166 21.4094 16.4374 21.1713L25.0119 11.9231C25.1444 11.7733 25.2129 11.5775 25.203 11.3778C25.193 11.1781 25.1053 10.9901 24.9586 10.8542C24.812 10.7183 24.6179 10.6451 24.418 10.6503C24.2181 10.6556 24.0281 10.7388 23.8888 10.8823Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-sm 2xl:text-base font-inter font-medium text-brand-blue">
                Automatic contents management by day, schedule and date range.{" "}
              </p>
            </li>
            <li className="flex gap-3">
              <div>
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16.5" cy="16.5" r="16.5" fill="#0059B1" />
                  <path
                    d="M23.8888 10.8823L15.3142 20.1305C15.2406 20.2098 15.1511 20.2726 15.0515 20.3148C14.9518 20.3569 14.8444 20.3775 14.7362 20.3752C14.6281 20.3728 14.5217 20.3476 14.424 20.3011C14.3263 20.2546 14.2395 20.188 14.1694 20.1056L10.9544 16.3219C10.8228 16.1671 10.6352 16.0709 10.4327 16.0544C10.2302 16.038 10.0294 16.1026 9.87458 16.2342C9.71976 16.3658 9.62353 16.5534 9.60708 16.7559C9.59063 16.9584 9.6553 17.1592 9.78685 17.314L13.0019 21.0966C13.2121 21.344 13.4724 21.5441 13.7656 21.6836C14.0588 21.8231 14.3782 21.8989 14.7028 21.906C15.0275 21.913 15.3499 21.8512 15.6489 21.7246C15.9478 21.5979 16.2166 21.4094 16.4374 21.1713L25.0119 11.9231C25.1444 11.7733 25.2129 11.5775 25.203 11.3778C25.193 11.1781 25.1053 10.9901 24.9586 10.8542C24.812 10.7183 24.6179 10.6451 24.418 10.6503C24.2181 10.6556 24.0281 10.7388 23.8888 10.8823Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-sm 2xl:text-base font-inter font-medium text-brand-blue">
                Explore innovative ways to enhance your brand with our apps.{" "}
              </p>
            </li>
            <li className="flex gap-3">
              <div>
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16.5" cy="16.5" r="16.5" fill="#0059B1" />
                  <path
                    d="M23.8888 10.8823L15.3142 20.1305C15.2406 20.2098 15.1511 20.2726 15.0515 20.3148C14.9518 20.3569 14.8444 20.3775 14.7362 20.3752C14.6281 20.3728 14.5217 20.3476 14.424 20.3011C14.3263 20.2546 14.2395 20.188 14.1694 20.1056L10.9544 16.3219C10.8228 16.1671 10.6352 16.0709 10.4327 16.0544C10.2302 16.038 10.0294 16.1026 9.87458 16.2342C9.71976 16.3658 9.62353 16.5534 9.60708 16.7559C9.59063 16.9584 9.6553 17.1592 9.78685 17.314L13.0019 21.0966C13.2121 21.344 13.4724 21.5441 13.7656 21.6836C14.0588 21.8231 14.3782 21.8989 14.7028 21.906C15.0275 21.913 15.3499 21.8512 15.6489 21.7246C15.9478 21.5979 16.2166 21.4094 16.4374 21.1713L25.0119 11.9231C25.1444 11.7733 25.2129 11.5775 25.203 11.3778C25.193 11.1781 25.1053 10.9901 24.9586 10.8542C24.812 10.7183 24.6179 10.6451 24.418 10.6503C24.2181 10.6556 24.0281 10.7388 23.8888 10.8823Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-sm 2xl:text-base font-inter font-medium text-brand-blue">
                The interchangeability and reuse of its content is possible on
                any of its screens.{" "}
              </p>
            </li>
            <li className="flex gap-3">
              <div>
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16.5" cy="16.5" r="16.5" fill="#0059B1" />
                  <path
                    d="M23.8888 10.8823L15.3142 20.1305C15.2406 20.2098 15.1511 20.2726 15.0515 20.3148C14.9518 20.3569 14.8444 20.3775 14.7362 20.3752C14.6281 20.3728 14.5217 20.3476 14.424 20.3011C14.3263 20.2546 14.2395 20.188 14.1694 20.1056L10.9544 16.3219C10.8228 16.1671 10.6352 16.0709 10.4327 16.0544C10.2302 16.038 10.0294 16.1026 9.87458 16.2342C9.71976 16.3658 9.62353 16.5534 9.60708 16.7559C9.59063 16.9584 9.6553 17.1592 9.78685 17.314L13.0019 21.0966C13.2121 21.344 13.4724 21.5441 13.7656 21.6836C14.0588 21.8231 14.3782 21.8989 14.7028 21.906C15.0275 21.913 15.3499 21.8512 15.6489 21.7246C15.9478 21.5979 16.2166 21.4094 16.4374 21.1713L25.0119 11.9231C25.1444 11.7733 25.2129 11.5775 25.203 11.3778C25.193 11.1781 25.1053 10.9901 24.9586 10.8542C24.812 10.7183 24.6179 10.6451 24.418 10.6503C24.2181 10.6556 24.0281 10.7388 23.8888 10.8823Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-sm 2xl:text-base font-inter font-medium text-brand-blue">
                Manage all your screens using a single interactive platform..{" "}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
