import { useEffect, useState } from "react";
import { switchReel } from "../../redux/feature/modalSlice";
import { moduleInfo } from "../../redux/feature/moduleSlice";
import { slideList } from "../../redux/feature/slideSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";


const updateReel = (
    countCuote: number,
    module: string,
    currentReel: string[]
) => {
    const reelLength = currentReel.length + countCuote;
    const reel = new Array(reelLength).fill(null);

    let slotStart = Math.floor(reelLength / countCuote);
    const indexList = [];
    for (let i = 0; i < countCuote; i++) {
        let index = i * slotStart + Math.floor(slotStart / 2);
        indexList.push(index);
    }

    indexList.forEach((index) => {
        reel[index] = module;
    });

    const remaining: any = [];
    currentReel.forEach((eOfReel) => {
        remaining.push(eOfReel);
    });

    let j = 0;
    for (let i = 0; i < reel.length; i++) {
        if (reel[i] == null) {
            reel[i] = remaining[j++];
        }
    }
    return reel;
};

const colors: any = {
    "IMAGE": "#66BB6A",
    "VIDEO": "#E53935",
    "TRIVIA": "#FFC107",
    "LIKETODAY": "#7E57C2",
    "DIDYOUNOW": "#3949AB",
    "MOVIE": "#0288D1",
    "SHOWS": "#9E9E9E",
    "REVIEWS": "#FF5722"

}

export const PreviewReel = ({ text, type = "submit", pending = false }: any) => {
    const [reel, setReel] = useState<string[]>([]);
    const list = useAppSelector(slideList);
    const dispatch = useAppDispatch();
    const moduleInfoData: any = useAppSelector(moduleInfo);
    const isImage = (content: any) => {
        return (
            content.thumb.endsWith('.png') ||
            content.thumb.endsWith('.jpg') ||
            content.thumb.endsWith('.jpeg')
        );
    }

    useEffect(() => {
        buildReel();
        console.log(moduleInfoData);

    }, [list.length, moduleInfoData])


    const buildReel = () => {
        let reelList: string[] = []
        list.map(e => ({ ...e, source: isImage(e) ? 'image' : 'video', })).forEach((element: any) => {
            if (element.source === 'image') reelList.push("IMAGE");
            if (element.source === 'video') reelList.push("VIDEO");
        });


        if (moduleInfoData?.modules?.trivia?.enable)
            reelList = updateReel(
                moduleInfoData?.modules?.trivia?.repeat,
                "TRIVIA",
                reelList
            );
        if (moduleInfoData?.modules?.a_day_like_today?.enable)
            reelList = updateReel(
                moduleInfoData?.modules?.a_day_like_today?.repeat,
                "LIKETODAY",
                reelList
            );
        if (moduleInfoData?.modules?.did_you_know?.enable)
            reelList = updateReel(
                moduleInfoData?.modules?.did_you_know?.repeat,
                "DIDYOUNOW",
                reelList
            );
        if (moduleInfoData?.modules?.movie?.enable)
            reelList = updateReel(
                moduleInfoData?.modules?.movie?.repeat,
                "MOVIE",
                reelList
            );
        if (moduleInfoData?.modules?.show?.enable)
            reelList = updateReel(
                moduleInfoData?.modules?.show?.repeat,
                "SHOWS",
                reelList
            );
        if (moduleInfoData?.modules?.review?.enable)
            reelList = updateReel(
                moduleInfoData?.modules?.review?.repeat,
                "REVIEWS",
                reelList
            );
        if (moduleInfoData?.modules?.instagram?.enable)
            reelList = updateReel(
                moduleInfoData?.modules?.instagram?.repeat,
                "INSTAGRAM",
                reelList
            );
        setReel(reelList);

    }

    return <>


        <>
            <div className="bg-white absolute top-0 left-0 h-full w-full z-50 opacity-0"></div>
            <div
                className="absolute left-0 right-0 top-9 mx-auto w-[80%]  border bg-brand-gray rounded-3xl shadow z-50 "
                style={{ zIndex: "1000000 !important" }}
            >
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div style={{fontWeight: 'bold',fontSize:16 }} className="pt-5 ml-10">Reel preview</div>

                    <button
                        type="button"

                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                        data-modal-toggle="popup-modal"
                        onClick={() =>
                            dispatch(
                                switchReel(false)
                            )
                        }
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center max-w-100 ">
                        <div className="flex flex-col justify-start items-start text-sm">
                            <span><span style={{fontWeight: 'bold' }}>- Reel Length: </span>{reel.length}</span>
                            <span><span style={{fontWeight: 'bold' }}>- Content Item Length: </span>{reel.filter(e=>(e==='IMAGE' || e ==='VIDEO')).length}</span>
                            <span><span style={{fontWeight: 'bold' }}>- Trivia Module Item Length: </span>{reel.filter(e=>(e==='TRIVIA')).length}</span>
                            <span><span style={{fontWeight: 'bold' }}>- Did you now Module Item Length: </span>{reel.filter(e=>(e==='DIDYOUNOW')).length}</span>
                            <span><span style={{fontWeight: 'bold' }}>- A like to day Module Item Length: </span>{reel.filter(e=>(e==='LIKETODAY')).length}</span>
                            <span><span style={{fontWeight: 'bold' }}>- Shows Module Item Length: </span>{reel.filter(e=>(e==='SHOWS')).length}</span>
                            <span><span style={{fontWeight: 'bold' }}>- Reviews Module Item Length: </span>{reel.filter(e=>(e==='REVIEWS')).length}</span>
                            <span><span style={{fontWeight: 'bold' }}>- Movies Module Item Length: </span>{reel.filter(e=>(e==='MOVIE')).length}</span>
                            <br />
                            <span style={{fontWeight: 'bold',fontSize:16 }}>Reel distribution</span>

                        </div>

                        <div className="flex flex-row overflow-x-scroll pb-10 pt-10">
                            {reel.map((e, i) => (
                                <div style={{ backgroundColor: colors[e] }} className="py-1.5 w-40 h-20 px-4 flex flex-col center justify-center items-center   hover:border-brand-green  hover:bg-white border-2 rounded-lg font-inter text-md font-medium text-white hover:text-brand-green text-center">
                                    <div> {e}</div>
                                    <div style={{ fontSize: 12 }}> POS: {(i+1)}</div>
                                </div>
                            ))}
                        </div>





                        <button
                            onClick={() =>
                                dispatch(switchReel(false))
                            }
                            data-modal-toggle="popup-modal"
                            type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    </>
};
