import React, { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

// guards
import GuestGuard from "../guards/GuestGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// components

import { PATH_AUTH, PATH_PAGE } from "./paths";
import LoadingScreen from "../components/common/LoadingScreen";
import MainLayout from "../screen/common/MainLayout";
import AuthGuard from "../guards/AuthGuard";
// ----------------------------------------------------------------------
const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    // Main Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        //{ path: PATH_PAGE.term, element: <TermPage /> },

        //Organization
        {
          path: PATH_PAGE.organization + "/:organization_uuid/",
          element: <HomePage />,
        },

        {
          path: PATH_PAGE.trivia,
          element: <TriviaListPage />,
        },

        {
          path:
            PATH_PAGE.organization +"/:organization_uuid/emission/:emision_uuid",
          element: <EmisionDataPage />,
        },
        { path: "500", element: <Page500 /> },
        { path: PATH_PAGE.page404, element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to={PATH_PAGE.page404} replace /> },
      ],
    },

    
    { path: "*", element: <Navigate to={PATH_PAGE.page404} replace /> },
  ]);
}

// AUTHENTICATION
const LoginPage = Loadable(lazy(() => import("../screen/auth/Login")));

// MAIN
const HomePage = Loadable(lazy(() => import("../screen/emision/Home")));
const TriviaListPage = Loadable(lazy(() => import("../screen/main/TriviaList")));

 
const EmisionDataPage = Loadable(
  lazy(() => import("../screen/emision/EmisionScreen"))
);
 
//COMMON

const Page500 = Loadable(lazy(() => import("../screen/common/Page500")));
const Page403 = Loadable(lazy(() => import("../screen/common/Page403")));
const Page404 = Loadable(lazy(() => import("../screen/common/Page404")));
